const colors = mainTheme => {
  return {
    color: mainTheme.regular
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.default,
    lineHeight: 1.5
  };
};

const defaultParagraphCSSProperties = (mainTheme, mainFont) => {
  return {
    main: {
      ...colors(mainTheme),
      ...font(mainFont),
      fontSize: "1.6rem"
    }
  };
};

export default defaultParagraphCSSProperties;
