import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Page from "../../layouts/page";

export const StyledNotFoundPage = styled(Page)`
  position: relative;
  width: 100%;
  .title {
    font-weight: 700;
    width: auto;
  }
  .title,
  .notFound-intro {
    font-size: 3.2rem;
  }
  .notFound-intro {
    line-height: 1.15;
  }
  .notFound-link {
    a {
      align-items: center;
      color: inherit;
      display: flex;
      flex-flow: column wrap;
      font-weight: 700;
      justify-content: center;
      &:after {
        background-color: ${props => props.theme.colors.themeColors.regular};
        content: "";
        height: 1px;
        margin-top: 3px;
        width: 90%;
      }
      &:hover {
        color: ${props => props.theme.colors.themeColors.ultralight};
        &:after {
          background-color: ${props =>
            props.theme.colors.themeColors.ultralight};
        }
      }
    }
  }
  .wrapper-content {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    padding-top: 60px;
    > div {
      align-items: center;
      display: flex;
      flex-flow: column wrap;
      max-width: 70%;
    }
  }
  .svg-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
    &:after {
      background-color: ${props => props.theme.colors.themeColors.white};
      content: "";
      display: block;
      height: 100vh;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% - 33px);
      position: absolute;
      z-index: 30;
      width: 100vw;
    }
    svg {
      position: relative;
      z-index: 60;
    }
  }
  ${breakpoint("mobile", "tablet")`
    .wrapper-content {
      > div {
        max-width: 100%;
      }
    }
    .svg-container {
      svg {
        width: 300px;
      }
      &:after {
        content: '';
        top: calc(100% - 64px);
      }
    }
  `};
`;
