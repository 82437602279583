export const SET_CONFIGURATION = {
  REQUEST: "configuration/SET/REQUEST",
  SUCCESS: "configuration/SET/SUCCESS",
  FAILURE: "configuration/SET/FAILURE"
};

export const setConfiguration = envURL => {
  console.log("call REDUX setConfiguration", envURL);
  return {
    type: SET_CONFIGURATION.REQUEST,
    envURL
  };
};
