import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import { Button, InternalLink, OutsideLink, RedirectButton } from "./style";

const LinkButton = props => {
  const {
    styletype,
    nostyle,
    uppercase,
    staticContext,
    buttonType,
    history,
    to,
    onClick,
    match,
    location,
    children,
    ...rest
  } = props;

  const noStyle = typeof nostyle !== "undefined" ? nostyle.toString() : "false";
  const upperCase =
    typeof uppercase !== "undefined" ? uppercase.toString() : "false";

  const childrenContent = children => <span>{children}</span>;

  const redirectTo = to => {
    return {
      pathname: to
    };
  };

  const isOutside = to => {
    const toCheck = ["http://", "https://", "www"];
    if (typeof to === "string") {
      const patternChecked = toCheck.reduce((error, pattern) => {
        return error + (to.indexOf(pattern) > -1 ? 1 : 0);
      }, 0);
      return patternChecked > 0;
    } else {
      return 0;
    }
  };

  const commonLinkButtonProps = () => {
    return {
      styletype: styletype ? styletype : "link",
      children: childrenContent(children),
      uppercase: upperCase ? upperCase : "false",
      ...rest
    };
  };

  if (styletype === "link") {
    if (typeof to !== "undefined")
      if (isOutside(to))
        return (
          <OutsideLink
            {...commonLinkButtonProps()}
            linkTo={to}
            nostyle={noStyle || "false"}
            {...rest}
          >
            <span>{children}</span>
          </OutsideLink>
        );
    return (
      <InternalLink
        {...commonLinkButtonProps()}
        to={to}
        nostyle={noStyle || "false"}
        {...rest}
      >
        <span>{children}</span>
      </InternalLink>
    );
  } else if (styletype === "button") {
    if (typeof to !== "undefined")
      return (
        <RedirectButton
          {...commonLinkButtonProps()}
          onClick={event => {
            // console.log("boop");
            onClick && onClick(event);
            history.push(redirectTo(to));
          }}
          staticContext={staticContext}
          history={history}
          match={match}
          location={location}
          {...rest}
        />
      );
    return (
      <Button buttonType={buttonType} {...rest}>
        <span>{children}</span>
      </Button>
    );
  }
  return null;
};

LinkButton.propTypes = {
  styletype: PropTypes.string,
  nostyle: PropTypes.bool,
  history: PropTypes.object,
  buttonType: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  onClick: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  className: PropTypes.string,
  noMargin: PropTypes.bool
};

export default withRouter(LinkButton);
