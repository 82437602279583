import { call, all, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";

import * as articleService from "./services";

export function* getArticles({ urlApi, isHomepage, categoryId, search }) {
  try {
    let articles;
    if (categoryId && !search && !isHomepage) {
      articles = yield call(
        articleService.getArticles,
        urlApi,
        null,
        categoryId
      );
    } else if (search && !categoryId && !isHomepage) {
      articles = yield call(
        articleService.getArticles,
        urlApi,
        null,
        null,
        search
      );
    } else if (isHomepage && !search && !categoryId) {
      articles = yield call(
        articleService.getArticles,
        urlApi,
        true,
        null,
        search
      );
    } else {
      articles = yield call(articleService.getArticles, urlApi);
    }
    yield put({
      type: a.FETCH_ARTICLES.SUCCESS,
      payload: articles
    });
  } catch (e) {
    yield put({
      type: a.FETCH_ARTICLES.FAILURE,
      payload: e.message
    });
  }
}

export function* getArticle({ urlApi, articleId }) {
  try {
    const article = yield call(articleService.getArticle, urlApi, articleId);
    yield put({
      type: a.FETCH_ARTICLE.SUCCESS,
      payload: article
    });
  } catch (e) {
    yield put({
      type: a.FETCH_ARTICLE.FAILURE,
      payload: e.message
    });
  }
}

/* WATCHERS */
export function* watchGetArticles() {
  yield takeLatest(a.FETCH_ARTICLES.REQUEST, getArticles);
}
export function* watchGetArticle() {
  yield takeLatest(a.FETCH_ARTICLE.REQUEST, getArticle);
}
/*

export default all([
  takeLatest(a.FETCH_ARTICLES.REQUEST, getArticles),
  // takeLatest(a.FETCH_ARTICLE.REQUEST, getArticle)
]);
*/
