import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledNewsletterSignUp = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  margin-bottom: 30px;
  padding-bottom: 40px;
  border-bottom: 1px ${props => props.theme.colors.themeColors.light} solid;
  > div {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: ${props => props.theme.colors.themeColors.white};
    max-width: 460px;
    padding: 20px 30px;
    border-radius: 5px;
    > .title {
      font-weight: ${props => props.theme.fonts.main.variants.bold};
    }
    > .loaderSignUp {
      height: 100px;
      width: 100%;
    }
    > .warningEmailFormat {
      color: ${props => props.theme.colors.statusColors.error};
    }
    > form {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      > * {
        height: 35px;
        margin: 5px 0;
        transition: 250ms;
        ${breakpoint("mobile", "tablet")`
			    width:100%;
			  `};
      }
      button {
        background-color: ${props => props.theme.colors.themeColors.light};
        padding: 0 30px;
        vertical-align: middle;
        line-height: 35px;
        min-width: 100px;
        &:hover {
          background-color: ${props => props.theme.colors.themeColors.regular};
        }
        span {
          width: 100%;
          text-align: center;
        }
      }
      input {
        min-width: 200px;
        border: 1px
          ${props =>
            props.isEmailFormatValid
              ? props.theme.colors.themeColors.light
              : props.theme.colors.statusColors.error}
          solid;
        border-radius: 5px;
        padding-right: 10px;
        &:focus {
          border-color: ${props => props.theme.colors.themeColors.ultralight};
        }
        ${breakpoint("tablet")`
					margin-right: 10px;
		    `};
      }
    }
    > .paragraph {
      margin-top: 15px;
    }
  }
  > .paragraph {
    margin-top: 15px;
    color: ${props => props.theme.colors.themeColors.white};
  }
`;
