import styled, { keyframes } from "styled-components";

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;
const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const StyledLoaderData = styled.div.attrs({
  className: "loader-data"
})`
  height: 100%;
  display: flex;
  width: 100%;
  min-height: 100px;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  > div {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    > div {
      position: absolute;
      top: 27px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: ${props =>
        props.alt
          ? props.theme.colors.themeColors.white
          : props.theme.colors.themeColors.regular};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      &:nth-child(1) {
        left: 6px;
        animation: ${ellipsis1} 0.6s infinite;
      }
      &:nth-child(2) {
        left: 6px;
        animation: ${ellipsis2} 0.6s infinite;
      }
      &:nth-child(3) {
        left: 26px;
        animation: ${ellipsis2} 0.6s infinite;
      }
      &:nth-child(4) {
        left: 45px;
        animation: ${ellipsis3} 0.6s infinite;
      }
    }
  }
`;
