const colors = mainTheme => {
  return {
    color: mainTheme.regular
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: 1.25
  };
};

const mainMarginBottom = () => {
  return { marginBottom: "15px" };
};

const defaultTitleCSSProperties = (mainTheme, mainFont) => {
  return {
    lv1: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "3.6rem",
        marginBottom: "15px",
        responsive: {
          mobile: {
            fontSize: "2.5rem"
          },
          tablet: {
            fontSize: "3rem"
          },
          desktop: {
            fontSize: "3.6rem"
          },
          lgDesktop: {
            fontSize: "3.6rem"
          }
        }
      }
    },
    lv2: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "3.1rem"
      }
    },
    lv3: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "2.8rem"
      }
    },
    lv4: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "2.4rem"
      }
    },
    lv5: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "2rem"
      }
    },
    lv6: {
      main: {
        ...colors(mainTheme),
        ...font(mainFont),
        ...mainMarginBottom(),
        fontSize: "1.8rem"
      }
    }
  };
};

export default defaultTitleCSSProperties;
