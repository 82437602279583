import { call, all, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";

import * as newsletterService from "./services";

export function* createNewsletter({ urlApi, email }) {
  try {
    const newsletter = yield call(newsletterService.create, urlApi, email);
    yield put({
      type: a.CREATE_NEWSLETTER.SUCCESS,
      payload: newsletter
    });
  } catch (e) {
    yield put({
      type: a.CREATE_NEWSLETTER.FAILURE,
      payload: e.message
    });
  }
}

// export default all([takeLatest(a.CREATE_NEWSLETTER.REQUEST, createNewsletter)]);

/* WATCHERS */
export function* watchCreateNewsletter() {
  yield takeLatest(a.CREATE_NEWSLETTER.REQUEST, createNewsletter);
}
