import * as a from "./actions";

const INITIAL_ARTICLE_STATE = {
  articlesList: {
    articles: [],
    error: null,
    loading: false,
    success: false
  },
  activeArticle: {
    article: null,
    error: null,
    loading: false,
    success: false
  }
};

export function article(state = INITIAL_ARTICLE_STATE, action) {
  let error;
  switch (action.type) {
    case a.FETCH_ARTICLES.REQUEST:
      return {
        ...state,
        articlesList: {
          articles: [],
          error: null,
          loading: true,
          success: false
        }
      };
    case a.FETCH_ARTICLES.SUCCESS:
      return {
        ...state,
        articlesList: {
          articles: action.payload,
          error: null,
          loading: false,
          success: true
        }
      };
    case a.FETCH_ARTICLES.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        articlesList: {
          articles: [],
          error: error,
          loading: true
        }
      };
    case a.RESET_ARTICLES:
      return {
        ...state,
        articlesList: {
          articles: [],
          error: null,
          loading: false,
          success: false
        }
      };
    case a.FETCH_ARTICLE.REQUEST:
      return {
        ...state,
        activeArticle: {
          ...state.activeArticle,
          loading: true,
          success: false
        }
      };
    case a.FETCH_ARTICLE.SUCCESS:
      return {
        ...state,
        activeArticle: {
          article: action.payload,
          error: null,
          loading: false,
          success: true
        }
      };
    case a.FETCH_ARTICLE.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        activeArticle: {
          article: null,
          error: error,
          loading: false,
          success: false
        }
      };
    case a.RESET_ACTIVE_ARTICLE:
      return {
        ...state,
        activeArticle: {
          article: null,
          error: null,
          loading: false,
          success: false
        }
      };
    default:
      return state;
  }
}

export default article;
