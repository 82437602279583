const genericColors = {
  black: "rgb(0,0,0)",
  black50: "rgba(0,0,0, 0.5)",
  black80: "rgba(0,0,0, 0.8)",
  white: "rgb(255,255,255)",
  white50: "rgba(255,255,255, 0.5)",
  white80: "rgba(255,255,255, 0.8)"
};

const bgColors = {
  light: "#f5f5f5",
  regular: "lightgrey"
};

const statusColors = {
  valid: "rgb(49,83,13)",
  // error: "rgb(82,1,11)",
  error: "red"
};

const baloonColors = {
  ultralight: "rgb(0,168,158)",
  ultralight50: "rgba(0,168,158, 0.5)",
  light: "rgb(51,104,127)",
  light0: "rgba(51,104,127, 0)",
  light20: "rgba(51,104,127, 0.2)",
  light50: "rgba(51,104,127, 0.5)",
  light80: "rgba(51,104,127, 0.8)",
  regular: "rgb(38, 79, 96)",
  dark: "rgb(12,40,53)",
  altLight: "rgb(224,188,0)",
  altRegular: "rgb(186,147,28)",
  altDark: "rgb(124,102,15)",
  ...genericColors
};

const defaultThemeColors = baloonColors;

const colors = {
  brandColors: {
    baloon: baloonColors
  },
  themeColors: defaultThemeColors,
  bgColors: bgColors,
  statusColors: statusColors
};

export default colors;
