// French language

// Main logo
import logo from "../assets/logo.jpg";

export default {
  config: {
    defaultSep: " | "
  },
  global: {
    siteName: "Assurance pour tous",
    description: "Blog d'informations grand public sur l'Assurance en Afrique.",
    loader: {
      text: "Merci de patienter…",
      error: "Il semblerai qu'il y ai eu une erreur, veuillez recharger la page"
    }
  },
  logoText: {
    main: "Assurance",
    alt: "pour tous"
  },
  footer: {
    signUp: {
      title: "S'inscrire à notre newsletter",
      inputPlaceholder: "Entrer votre email",
      buttonLabel: "S'inscrire",
      notReady:
        "Recevez dans votre boîte mail le meilleur du blog Assurance pour tous",
      thanks: "Merci pour votre souscription !",
      warningEmailFormat: "Désolé, le format de votre email n'est pas valide."
    },
    mainText:
      "Assurance pour tous est un blog d'informations grand public sur l'Assurance en Afrique. Son but est de renseigner sur les actus et les innovations du secteur et donner des conseils pratiques pour vivre \"bien assuré\".",
    contactUs: "Nous contacter : <1>contact@assurancepourtous.africa</1>",
    allRightReserved: "Tous droits réservés",
    links: {
      legal: {
        label: "Politique de confidentialité",
        url: "/politique-confidentialite"
      },
      conditions: {
        label: "Conditions générales d'utilisation",
        url: "/conditions-generales"
      }
    }
  },
  social: {
    defaultImageURL: logo,
    facebook: {
      appId: "XXXXXXXXX"
    },
    twitter: {
      userName: "@BaloonAfrica"
    },
    emoji: {
      smile: "Emoji Souriant",
      love: "Emoji Amoureux",
      surprised: "Emoji Surpris",
      thumbsUp: "Emoji Pouce en l'air"
    }
  },
  cookieBar: {
    text:
      "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies. Ces derniers assurent le bon fonctionnement de nos services et des outils d'analyse. <1>En savoir plus et agir</1> sur les cookies.",
    buttonLabel: "J'accepte"
  },
  homepage: {
    articleList: {
      title: "A la une"
    }
  },
  article: {
    endMetaSeparator: "•",
    publishedOn: "Publié le",
    in: "dans",
    minutesRead: "min",
    shareArticle: "Partager l'article",
    reactionsTitle: "Qu'en pensez vous ?",
    moreReading: "Vous aimerez aussi",
    source: "Source"
  },
  category: {
    categories: "Catégories",
    allArticlesOf: "Tous les articles de"
  },
  reactions: {
    peopleAnswer_0: "Soyez le premier à vous émouvoir !",
    peopleAnswer: "{{count}} personne a déjà répondu",
    peopleAnswer_plural: "{{count}} personnes ont déjà répondu",
    firstVote: "Merci pour votre contribution",
    changedVote: "Nous avons bien pris en compte votre changement de vote"
  },
  search: {
    title: "Recherche",
    yourSearch: "Votre recherche",
    equalsToYourSearch: "articles correspondent à votre recherche",
    placeholder: "Recherche"
  },
  notFound: {
    metaTitle: "Page non trouvée",
    title: "Oops !",
    text: "Cette page ne peut s'afficher",
    link: {
      label: "Retour accueil",
      url: "/"
    }
  },
  privacyPolicy: {
    title: "Politique de confidentialité",
    description: "Description Mentions légales",
    contents: {
      block1: {
        paragraph1:
          "La présente politique de confidentialité définit et vous informe de la manière dont « Assurance Pour Tous » utilise et protège les informations que vous nous transmettez, le cas échéant, lorsque vous utilisez le présent site accessible à partir de l’URL suivante : <1>https://www.assurancepourtous.africa</1> (ci-après le « Site »).",
        paragraph2:
          "Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout moment par « Assurance Pour Tous », notamment en vue de se conformer à toute évolution législative, réglementaire, jurisprudentielle ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en tête de la présente politique. Ces modifications engagent l’Utilisateur dès leur mise en ligne. Il convient par conséquent que l’Utilisateur consulte régulièrement la présente politique de confidentialité et d’utilisation des cookies afin de prendre connaissance de ses éventuelles modifications."
      },
      block2: {
        title: "I. Données personnelles",
        paragraph1:
          "D’une manière générale, il vous est possible de visiter le Site « Assurance Pour Tous » sans communiquer aucune information personnelle vous concernant. En toute hypothèse, vous êtes en aucune manière obligé de transmettre ces informations à « Assurance Pour Tous ».",
        paragraph2:
          "Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations ou services que vous avez demandé. A ce titre en effet, « Assurance Pour Tous » peut être amené dans certains cas à vous demander de renseigner vos nom, prénom, adresse mail, numéro de téléphone, entreprise et fonction (ci-après vos « Informations Personnelles »). En fournissant ces informations, vous acceptez expressément qu’elles soient traitées par « Assurance Pour Tous », aux fins indiquées au point 2 ci-dessous ainsi qu’aux fins rappelées à la fin de chaque formulaire.",
        paragraph3:
          "Conformément au Règlement Général sur la Protection des Données (RGPD) adopté par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, « Assurance Pour Tous » vous informe des points suivants :",
        subTitle1: "Identité du responsable du traitement",
        paragraph4:
          "Le responsable du traitement est Monsieur Emmanuel HEBERT – 23/25 rue Dumont D’Urville – 75116 – PARIS",
        paragraph5: "Tél. : + 33 (0)6 27 21 17 71",
        subTitle2: "Finalités du traitement",
        paragraph6:
          "« Assurance Pour Tous » est susceptible de traiter vos Informations Personnelles :",
        paragraph7: "(a) si vous avez demandé l’envoi de la Newsletter ; et/ou",
        paragraph8:
          "(b) aux fins de recueillir des informations nous permettant d’améliorer notre Site",
        subTitle3: "Destinataires",
        paragraph9:
          "Seul « Assurance Pour Tous » est destinataire de vos Informations Personnelles. Celles-ci, que ce soit sous forme individuelle ou agrégée, ne sont jamais transmises à un tiers, nonobstant les sous-traitants auxquels « Assurance Pour Tous » fait appel (vous trouverez de plus amples informations à leur sujet au point 7 ci-dessous). Ni « Assurance Pour Tous », ni l’un quelconque de ses sous-traitants, ne procèdent à la commercialisation des données personnelles des visiteurs et Utilisateurs de son Site.",
        subTitle4: "Durée de conservation",
        paragraph10:
          "Vos Informations Personnelles sont conservées par « Assurance Pour Tous » uniquement pour le temps correspondant à la finalité de la collecte tel qu’indiqué en 2 ci-dessus qui ne saurait en tout état de cause excéder 24 mois.",
        subTitle5: "Droits Informatique et Libertés",
        paragraph11:
          "Vous disposez des droits suivants concernant vos Informations Personnelles, que vous pouvez exercer en nous écrivant à l’adresse postale mentionnée au point 1.",
        subTitle6: "Droit d’accès et de communication des données",
        paragraph12:
          "Vous avez la faculté d’accéder aux Informations Personnelles qui vous concernent.",
        paragraph13:
          "Cependant, en raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui incombe à « Assurance Pour Tous », vous êtes informé que votre demande sera traitée sous réserve que vous apportiez la preuve de votre identité, notamment par la production d’une photocopie signée de votre titre d’identité valide.",
        paragraph14:
          "« Assurance Pour Tous » vous informe qu’il sera en droit, le cas échéant, de s’opposer aux demandes manifestement abusives (de par leur nombre, leur caractère répétitif ou systématique).",
        paragraph15:
          "Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’accès par le biais d’une demande écrite à l’adresse postale mentionnée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la Commission Nationale de l’Informatique et des Libertés (la « CNIL »).",
        paragraph16:
          "<0>https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces</0>",
        subTitle7: "Droit de rectification des données",
        paragraph17:
          "Au titre de ce droit, la législation vous habilite à demander la rectification, la mise à jour, le verrouillage ou encore l’effacement des données vous concernant qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.",
        paragraph18:
          "Également, vous pouvez définir des directives générales et particulières relatives au sort des données à caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.",
        paragraph19:
          "Pour vous aider dans votre démarche, notamment si vous désirez exercer, pour votre propre compte ou pour le compte de l’un de vos proches décédé, votre droit de rectification par le biais d’une demande écrite à l’adresse postale mentionnée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la CNIL.",
        paragraph20:
          "<0>https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees</0>",
        subTitle8: "Droit d’opposition",
        paragraph21:
          "L’exercice de ce droit n’est possible que dans l’une des deux situations suivantes :",
        paragraph22:
          "Lorsque l’exercice de ce droit est fondé sur des motifs légitimes ; ou\nLorsque l’exercice de ce droit vise à faire obstacle à ce que les données recueillies soient utilisées à des fins de prospection commerciale.\nPour vous aider dans votre démarche, notamment si vous désirez exercer votre droit d’opposition par le biais d’une demande écrite adressée à l’adresse postale indiquée au point 1, vous trouverez en cliquant sur le lien suivant un modèle de courrier élaboré par la CNIL.",
        paragraph23:
          "<0>https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet</0>",
        subTitle9: "Délais de réponse",
        paragraph24:
          "« Assurance Pour Tous » s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception de votre demande.",
        subTitle10:
          "Prestataires habilités et transfert vers un pays tiers de l’Union Européenne",
        paragraph25:
          "« Assurance Pour Tous » vous informe qu’il a recours à ses prestataires habilités pour faciliter le recueil et le traitement des données que vous nous avez communiqué. Ces prestataires peuvent être situés en dehors de l’Union Européenne et ont communication des données recueillies par le biais des divers formulaires présents sur le Site (à l’exception du formulaire vous permettant d’exercer vos droits Informatique et Libertés qui est proposé et exploité par « Assurance Pour Tous »).",
        paragraph26:
          "« Assurance Pour Tous » s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données. Tout particulièrement, la vigilance s’est portée sur l’existence d’un fondement légal pour effectuer un quelconque transfert de données vers un pays tiers.",
        subTitle11: "Plainte auprès de l’autorité compétente",
        paragraph27:
          "Si vous considérez que «Assurance Pour Tous» ne respecte pas ses obligations au regard de vos Informations Personnelles, vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la CNIL à laquelle vous pouvez adresser une demande par voie électronique en cliquant sur le lien suivant : <1>https://www.cnil.fr/fr/plaintes/internet</1>."
      },
      block3: {
        title: "II. Politique relative aux cookies",
        paragraph1:
          "Lors de votre première connexion sur le site web de « Assurance Pour Tous », vous êtes avertis par un bandeau en bas de votre écran que des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des fichiers dénommés « cookies ». Notre politique d’utilisation des cookies vous permet de mieux comprendre les dispositions que nous mettons en œuvre en matière de navigation sur notre site web. Elle vous informe notamment sur l’ensemble des cookies présents sur notre site web, leur finalité et vous donne la marche à suivre pour les paramétrer.",
        subTitle1:
          "Informations générales sur les cookies présents sur le site de « Assurance Pour Tous »",
        paragraph2:
          "«Assurance Pour Tous», en tant qu’éditeur du présent site web, pourra procéder à l’implantation d’un cookie sur le disque dur de votre terminal (ordinateur, tablette, mobile etc.) afin de vous garantir une navigation fluide et optimale sur notre site Internet.",
        paragraph3:
          "Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui nous permettent de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les services que nous vous proposons.",
        paragraph4:
          "Les informations recueillies par le biais des cookies ne permettent en aucune manière de vous identifier nominativement. Elles sont utilisées exclusivement pour nos besoins propres afin d’améliorer l’interactivité et la performance de notre site web et de vous adresser des contenus adaptés à vos centres d’intérêts. Aucune de ces informations ne fait l’objet d’une communication auprès de tiers sauf lorsque « Assurance Pour Tous » a obtenu au préalable votre consentement ou bien lorsque la divulgation de ces informations est requise par la loi, sur ordre d’un tribunal ou toute autorité administrative ou judiciaire habilitée à en connaître.",
        subTitle2: "Configuration de vos préférences sur les cookies",
        paragraph5:
          "Vous pouvez accepter ou refuser le dépôt de cookies à tout moment",
        paragraph6:
          "Lors de votre première connexion sur le site web de « Assurance Pour Tous », une bannière présentant brièvement des informations relatives au dépôt de cookies et de technologies similaires apparaît en bas de votre écran. Cette bannière vous avertit qu’en poursuivant votre navigation sur le site web de «Assurance Pour Tous» (en chargeant une nouvelle page ou en cliquant sur divers éléments du site par exemple), vous acceptez le dépôt de cookies sur votre terminal. Vous êtes également réputé avoir donné votre accord au dépôt de cookies en cliquant sur l’icône « X » à droite de la bannière figurant en haut de votre écran.",
        paragraph7:
          "Selon le type de cookie en cause, le recueil de votre consentement au dépôt et à la lecture de cookies sur votre terminal peut être impératif.",
        minSubTitle1: "a. Les cookies exemptés de consentement",
        paragraph8:
          "Conformément aux recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL), certains cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou faciliter la communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session, d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et gérés par « Assurance Pour Tous ».",
        minSubTitle2:
          "b. Les cookies nécessitant le recueil préalable de votre consentement",
        paragraph9:
          "Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la mesure où ils demeurent dans votre terminal jusqu’à leur effacement ou leur date d’expiration.",
        paragraph10:
          "De tels cookies étant émis par des tiers, leur utilisation et leur dépôt sont soumis à leurs propres politiques de confidentialité dont vous trouverez un lien ci-dessous. Cette famille de cookie regroupe les cookies de mesure d’audience (notamment Hotjar), les cookies publicitaires (auxquels « Assurance Pour Tous » n’a pas recours) ainsi que les cookies de partage de réseaux sociaux (notamment de Facebook, YouTube, Twitter et LinkedIn).",
        paragraph11:
          "Les cookies de mesure d’audience établissent des statistiques concernant la fréquentation et l’utilisation de divers éléments du site web (comme les contenus/pages que vous avez visité). Ces données participent à l’amélioration de l’ergonomie du site web de « Assurance Pour Tous ». Un outil de mesure d’audience est utilisé sur le présent site internet :",
        paragraph12:
          "Hotjar dont la politique de confidentialité est disponible (uniquement en anglais) à partir du lien suivant : <1>https://www.hotjar.com/legal/compliance/gdpr-commitment</1>.",
        paragraph13:
          "Les cookies de partage des réseaux sociaux sont émis et gérés par l’éditeur du réseau social concerné. Sous réserve de votre consentement, ces cookies vous permettent de partager facilement une partie du contenu publié sur le site de «Assurance Pour Tous», notamment par l’intermédiaire d’un « bouton » applicatif de partage selon le réseau social concerné. Quatre types de cookies de partage des réseaux sociaux sont présents sur le site de « Assurance Pour Tous » :",
        paragraph14:
          "Facebook, dont vous pouvez consulter la politique en matière de cookies en cliquant sur le lien suivant : <1>https://fr-fr.facebook.com/policies/cookies/</1>",
        paragraph15:
          "LinkedIn, dont vous trouverez la politique relative aux cookies en cliquant sur le lien suivant : <1>https://www.linkedin.com/legal/cookie-policy?_l=fr_FR</1>",
        paragraph16:
          "Twitter, dont vous trouverez les options dédiées au contrôle ou à la restriction de l’utilisation des cookies ainsi que la politique d’utilisation des cookies : <1>https://support.twitter.com/articles/20170518#</1>",
        paragraph17:
          "YouTube, dont vous trouverez l’aide nécessaire pour supprimer les cookies à partir du navigateur Google Chrome en cliquant sur le lien suivant : <1>https://support.google.com/youtube/answer/32050?hl=fr</1> mais également la politique complète en matière de cookies à partir du lien suivant : <2>https://www.google.fr/intl/fr/policies/technologies/cookies/</2>",
        minSubTitle3:
          "c. Vous disposez de divers outils de paramétrage des cookies",
        paragraph18:
          "La plupart des navigateurs Internet sont configurés par défaut de façon à ce que le dépôt de cookies soit autorisé. Votre navigateur vous offre l’opportunité de modifier ces paramètres standards de manière à ce que l’ensemble des cookies soit rejeté systématiquement ou bien à ce qu’une partie seulement des cookies soit acceptée ou refusée en fonction de leur émetteur.",
        paragraph19:
          "ATTENTION : Nous attirons votre attention sur le fait que le refus du dépôt de cookies sur votre terminal est néanmoins susceptible d’altérer votre expérience d’utilisateur ainsi que votre accès à certains services ou fonctionnalités du présent site web. Le cas échéant, «Assurance Pour Tous» décline toute responsabilité concernant les conséquences liées à la dégradation de vos conditions de navigation qui interviennent en raison de votre choix de refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du site. Ces conséquences ne sauraient constituer un dommage et vous ne pourrez prétendre à aucune indemnité de ce fait.",
        paragraph20:
          "Votre navigateur vous permet également de supprimer les cookies existants sur votre terminal ou encore de vous signaler lorsque de nouveaux cookies sont susceptibles d’être déposés sur votre terminal. Ces paramétrages n’ont pas d’incidence sur votre navigation mais vous font perdre tout le bénéfice apporté par le cookie.",
        paragraph21:
          "Veuillez ci-dessous prendre connaissance des multiples outils mis à votre disposition afin que vous puissiez paramétrer les cookies déposés sur votre terminal.",
        minSubTitle4: "d. Le paramétrage de votre navigateur Internet",
        paragraph22:
          "Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. Pour savoir de quelle manière modifier vos préférences en matière de cookies, vous trouverez ci-dessous les liens vers l’aide nécessaire pour accéder au menu de votre navigateur prévu à cet effet :",
        paragraph23:
          "Chrome : <1>https://support.google.com/chrome/answer/95647?hl=fr</1>",
        paragraph24:
          "Firefox : <1>https://support.mozilla.org/fr/kb/activer-desactiver-cookies</1>",
        paragraph25:
          "Internet <1>Explorer : https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11</1>",
        paragraph26:
          "Opera : <1>https://help.opera.com/en/latest/web-preferences/#cookies</1>",
        paragraph27:
          "Safari : <1>https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</1>",
        paragraph28:
          "Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter le site internet de la CNIL : <1>https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</1>.",
        paragraph29:
          "Copyright © 2019 – « Assurance Pour Tous » Conditions Générales Politique de Confidentialité"
      }
    }
  },
  cgu: {
    title: "Conditions générales d'utilisation",
    description: "Description Conditions générales d'utilisation",
    contents: {
      intro:
        "Le site « Assurance Pour Tous » (ci-après le « Site ») est proposé par la société ITC SAS, Société par actions simplifiée au capital de 31.130,00 Euros dont le siège social est situé 112 AV DE PARIS 94300 VINCENNES et immatriculée au RCS de Paris sous le numéro 828 405 316 (ci-après « la Société »). L'utilisation du Site est soumise aux présentes conditions générales d'utilisation.\nLa navigation sur le Site implique l'acceptation des présentes conditions générales par les utilisateurs. La Société se réserve le droit de modifier à tout moment et sans préavis les présentes conditions générales d'utilisation du site.",
      block1: {
        title: "I. Objet du site",
        paragraphs:
          "Le Site a pour objet de permettre aux internautes de consulter et de partager sur les réseaux sociaux les articles traitant du marché de Assurance en Afrique mis à disposition par les rédacteurs de « Assurance Pour Tous ».\n" +
          "La consultation du Site est ouverte à tous.\n" +
          "Le site publie également des contenus (ci-après « les Contenus ») ne provenant pas du travail des rédacteurs de « Assurance Pour Tous », mais qui ont au contraire été recueillis sur Internet. Le Site fonctionne alors comme un moteur de recherche puisqu'il met à votre disposition un extrait des Contenus et des liens hypertextes vous permettant d'accéder aux sites d'information référencés (blogs, forums, sites d'actualités, etc., ci-après les « Sites Référencés ») sur lesquels vous pourrez consulter l'intégralité des Contenus.\n" +
          "Les utilisateurs s'engagent à utiliser le Site et les contenus mis à leur disposition sur le Site dans le respect des présentes Conditions générales d'Utilisation.\n"
      },
      block2: {
        title: "II. Responsabilité relative aux contenus du Site",
        paragraphs:
          "Le Site contient des hyperliens vers des sites tiers. Les utilisateurs du Site reconnaissent et acceptent que la Société n'exerce aucun contrôle sur les sites tiers et n'assume aucune responsabilité quant à la nature et la qualité de leur contenu, la qualité de leur fonctionnement et la présence éventuelle de virus et autres programmes informatiques de nature à endommager les ordinateurs des utilisateurs."
      },
      block3: {
        title: "III. Utilisation du service",
        paragraphs:
          "Les utilisateurs du Site reconnaissent et acceptent que le Site ne saurait être tenue pour responsable des dommages indirects ou indirects découlant de l'utilisation du Site, et notamment de toutes pertes de profit, interruption des affaires, pertes de programmes ou d'autres données de votre système d'information ou de celui de tout tiers, et ce, même si LA SOCIÉTÉ est expressément informée de la possibilité de tels dommages.\nLes utilisateurs du Site reconnaissent et acceptent que la Société ne donne aucune garantie implicite ou explicite de bon fonctionnement ou de continuité de fonctionnement du Site et décharge la Société de toute responsabilité à cet égard."
      },
      block4: {
        title: "IV. Protection des données personnelles",
        paragraph1:
          "Nous recueillons certaines informations nominatives vous concernant. Ces informations relèvent de deux catégories : des informations personnelles d'identification lors de votre inscription à la newsletter (nom, prénom, adresse, adresse e-mail); des informations de tracking afin de comprendre votre navigation en amont de notre site et sur notre site par le biais de cookies. Un cookie est un petit fichier texte qui est stocké dans le navigateur de l'internaute et qui permet de tracker son activité par le biais du navigateur. La durée de vie de nos cookies est normalement d'une année, mais nous nous réservons le droit d'en modifier la durée dans la limite de ce qui est autorisé par la loi sans en avertir au préalable nos visiteurs ou nos clients.",
        paragraph2:
          "Voir la politique de confidentialité : <1>https://www.assurancepourtous.africa/politique-confidentialite</1>."
      },
      block5: {
        title: "V. Propriété des contenus",
        paragraphs:
          "Les contenus publiés sur les pages web du Site sont protégés par des droits d'auteur. Toute reproduction partielle ou totale, notamment à des fins publicitaire ou commerciale, ainsi que toute utilisation ou exploitation doivent préalablement faire l'objet d'un accord écrit de la Société. Seule est autorisée la reproduction unique et à des fins personnelles et non commerciales à condition que l'origine du contenu puisse rester déterminable, par exemple par l'indication de ses sources. Toute autre reproduction, distribution ou publication de contenus sans l'accord préalable de la Société est interdite.\nLa mention des Marques des Assureurs sur le Site ne constitue en aucune manière, de façon implicite ou explicite, une concession de droit d'utilisation, une licence ou une autorisation quelconque relative auxdites marques, sauf en cas d'autorisation écrite particulière et préalable de la Société ou des tiers concernés."
      }
    }
  }
};
