const mainArticleEndpoint = "/articles";

const articlesEndpoints = () => {
  return {
    all: mainArticleEndpoint,
    byHome: `${mainArticleEndpoint}?misEnAvant=true`,
    byCategory: categoryId =>
      `${mainArticleEndpoint}?categorieId=${categoryId}`,
    bySearch: search => `${mainArticleEndpoint}?motsCles=${search}`,
    byId: articleId => `${mainArticleEndpoint}/${articleId}`
  };
};

export default articlesEndpoints;
