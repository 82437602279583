import React from "react";
import SvgIcon from "../../components/svg-icons";

const Landscape404Media = props => {
  const { colors } = props;
  return (
    <SvgIcon width="481px" height="240px" viewBox="0 0 481 240">
      <g>
        <path
          fill={colors.background}
          d="M454.34,218.58c26.84,-13.22 39.23,-70.57 5.13,-80.18c-18.72,-5.28 -39.51,0.13 -49.74,-19.65c-14.82,-28.68 -23.83,-65.88 -56.31,-80.3c-21.46,-9.52 -51.25,-14.19 -73.98,-5.98c-21.31,7.69 -23.98,16.39 -42.52,19.67c-38.16,6.76 -73.59,-4.91 -109.63,15.43c-13.51,7.62 -19.43,16.2 -27.65,29.21c-30.99,49.08 -21.76,97.56 12.03,141.7z"
        />
        <path
          fill={colors.leaf}
          d="M451.09,146.76l9.46,-1.13v-2.13l-4.02,0.13l-2.66,-1.83l-10.56,0.54l-2.76,1.28zM456.1,159.16l9.46,-1.13v-2.13l-4.02,0.13l-2.66,-1.83l-10.56,0.54l-2.76,1.28zM461.6,152.66l-4.41,-2.04l-16.84,-0.87l-4.24,2.91l-6.41,-0.21v3.39l15.08,1.79zM420.71,137.12l-13.7,-0.76l-3.45,2.57l-5.21,-0.18v2.98l12.27,1.58l0.53,-0.17l18.25,5.83l19.56,-2.51v-4.75l-8.31,0.29l-5.5,-4.08l-11.58,0.64zM416.08,147.65l-16.95,2.03v3.84l7.2,-0.24l4.76,3.3l18.93,-0.98l4.95,-2.32z"
        />
        <path
          fill={colors.trunk}
          d="M436.45,154.9h-9.04l-1.33,61.29h12.5zM447.02,150.21l-1.39,-2.26l-16.23,7.66l1.91,3.12zM432.81,162.88l1.45,-3.17l-17.11,-5.7l-0.45,2.71zM416.83,156.43l1.77,-2.58l-2.82,-5.11l-1.24,1.39zM413.13,158.98l3.78,-2.61l-0.17,-1.25l-5.23,3.61zM418.58,154.85l-6.44,-2.43l0.2,1.27l4.66,1.76zM429.59,158.91l2.78,-2.25l-12.93,-12.47l-1.67,2.01zM418.46,146.33l2.06,-1.82l-1.5,-5.65l-1.54,1.05zM420.05,143.23l4.66,-1.76l0.2,-1.27l-6.44,2.43zM420.27,145.48l-5.66,-3.91l-0.11,1.28l4.1,2.83zM450.41,145.15l-0.78,-1.67l-4.53,4.12l1.47,2.44zM448.09,147l-4.15,-5.12l-0.5,1.19l3,3.7zM446.5,149.54l4.75,-0.42l0.51,-1.19l-6.56,0.57zM440.6,152.11l1.35,-2.22l-11.3,-5.1l-0.97,1.35zM429.96,145.93l0.82,-1.64l-2.76,-2.82l-0.53,1.13zM430.08,143.71l2.08,-2.59l-0.34,-0.83l-2.88,3.58zM430.96,144.93l-4.57,-0.42l0.36,0.82l3.31,0.3zM448.58,157.84l-0.56,-2.53l-17.27,1.92l0.77,3.5zM452.08,154.26l-0.19,-1.72l-4.91,2.45l0.33,2.52zM448.3,157.02l4.48,1.11l0.85,-0.95l-6.19,-1.53z"
        />
        <path
          fill={colors.leaf}
          d="M75.8,21.9l-8.27,-3.86l-31.59,-1.64l-7.95,5.5l-12.02,-0.39v6.39l28.28,3.38zM60.82,59.17l-8.27,-3.86l-31.59,-1.64l-7.95,5.5l-12.02,-0.39v6.39l28.28,3.38zM63.11,63.94l45.09,-5.38v-10.17l-19.16,0.63l-12.67,-8.75l-50.37,2.61l-13.18,6.14zM126.57,6.61l-34.62,-1.94l-16.44,12.27l-24.86,-0.88v14.27l58.5,7.55l54.58,-17.53l1.57,0.51l36.69,-4.75v-8.97l-15.59,0.55l-10.31,-7.71l-40.98,2.3zM92.44,50.87l14.81,6.96l56.61,2.95l14.24,-9.91l21.54,0.71v-11.53l-50.68,-6.1z"
        />
        <path
          fill={colors.cloud}
          d="M225.8,61.89c-1.81,-3.5 -5.42,-5.89 -9.59,-5.89c-4.45,0 -8.27,2.73 -9.94,6.64c-0.97,-0.51 -2.07,-0.8 -3.24,-0.8c-3.89,0 -7.04,3.21 -7.04,7.16h36c0,-3.66 -2.71,-6.69 -6.2,-7.11z"
        />
        <path
          fill={colors.rightPanel}
          d="M263,71v150v0h10v0v-150c0,-2.76 -2.23,-5 -5,-5v0c-2.77,0 -5,2.24 -5,5z"
        />
        <path
          fill={colors.darkBg}
          d="M268,129v31v0h101.69v0l9.08,-12.55c1.3,-1.79 1.27,-4.67 -0.06,-6.44l-9.02,-12.01v0h-101.69z"
        />
        <path
          fill={colors.trunk}
          d="M267,127v31v0h101.69v0l9.08,-12.55c1.3,-1.79 1.27,-4.67 -0.06,-6.44l-9.02,-12.01v0h-101.69z"
        />
        <path
          fill={colors.darkBg}
          d="M264,95h-89.65v0l-8.96,12.01c-1.32,1.77 -1.35,4.66 -0.06,6.45l9.01,12.54v0h89.65v0v-31z"
        />
        <path
          className="shp6"
          fill={colors.leftPanel}
          d="M263,93h-89.65v0l-8.96,12.01c-1.32,1.77 -1.35,4.66 -0.06,6.45l9.01,12.54v0h89.65v0v-31z"
        />
        <path
          fill={colors.darkBg}
          d="M268,83v31v0h89.65v0l9.01,-12.54c1.29,-1.8 1.27,-4.68 -0.06,-6.45l-8.96,-12.01v0h-89.65z"
        />
        <path
          fill={colors.rightPanel}
          d="M267,81v31v0h89.65v0l9.01,-12.54c1.29,-1.8 1.27,-4.68 -0.06,-6.45l-8.96,-12.01v0h-89.65z"
        />
        <path
          fill={colors.cloud}
          d="M262,71v150v0h10v0v-150c0,-2.76 -2.23,-5 -5,-5v0c-2.77,0 -5,2.24 -5,5z"
        />
        <path fill={colors.cloud} d="M-304,375h1136v-166h-1136z" />
        <path
          fill={colors.trunk}
          d="M147.83,59.17l-1.22,-8.75l-50.11,18.99l4.12,10.2zM155,40.17l-3.41,-4.1l-8.49,15.77l4.81,7.68zM163.98,66.29l-15.1,-11.38l-0.78,4.38l10.92,8.23zM147.8,56.92l13.64,-5.79l0.45,-4.51l-18.85,8zM83.73,239.93h37.08l-3.94,-185.13h-26.82zM105.59,66.59l5.33,-9.62l-47.44,-24.96l-3.86,6.96zM143.81,27.59l-4.94,-6.54l-36.68,38.99l8.18,7.37zM146.12,9.43l-4.29,-3.15l-4.43,17.35l5.74,5.51zM143.34,17.8l-18.85,-8l0.45,4.51l13.64,5.79zM143.19,27.43l11.83,-8.92l-0.65,-4.48l-16.36,12.33zM60.4,40.16l3.97,-7.05l-13.72,-12.82l-2.05,4.85zM60.61,30.62l8.46,-11.39l-1.74,-3.96l-11.69,15.75zM64.12,35.68l-19.45,-2.53l1.64,4l14.07,1.83zM109.44,27.08l-2.68,-4.08l-32.99,16.68l3.71,6.73zM105.19,72.55l1.83,-10.51l-51.28,-7.98l-1.33,7.61zM116.66,17.5l-1.4,-3.29l-8.4,8.74l2.17,4.78zM112.21,21.51l-8.11,-11l-1.22,2.77l5.87,7.95zM108.9,27.09l9.8,-1.3l1.15,-2.8l-13.55,1.8zM58.15,62.55l0.7,-7.26l-14.98,-7.66l-0.36,4.95zM57.5,57.22l-18.61,3.78l2.77,3.15l13.47,-2.73z"
        />
        <path
          fill={colors.trunk}
          d="M295.07,208.08l-4.11,-3.7c-0.9,-0.81 -1.89,-1.53 -2.93,-2.13l-5.65,-3.26h-7.88l-7.86,4.15c-0.02,0.01 -0.04,0.02 -0.06,0.03l-9.21,4.9c-1.23,0.65 -1.72,2.22 -1.09,3.5c0.43,0.87 1.29,1.42 2.23,1.42h34.81c1.49,0 2.69,-1.25 2.69,-2.8c0,-0.81 -0.34,-1.59 -0.93,-2.12z"
        />
      </g>
    </SvgIcon>
  );
};

export default Landscape404Media;
