import * as a from "./actions";

const INITIAL_NEWSLETTER_STATE = {
  newsletter: {
    email: null,
    error: null,
    loading: false
  }
};

export function newsletter(state = INITIAL_NEWSLETTER_STATE, action) {
  let error;
  switch (action.type) {
    case a.CREATE_NEWSLETTER.REQUEST:
      console.log(action, state);
      return {
        ...state,
        newsletter: {
          email: null,
          error: null,
          loading: true
        }
      };
    case a.CREATE_NEWSLETTER.SUCCESS:
      return {
        ...state,
        newsletter: {
          email: action.payload,
          error: null,
          loading: false
        }
      };
    case a.CREATE_NEWSLETTER.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        newsletter: {
          email: null,
          error: error,
          loading: true
        }
      };
    case a.RESET_NEW_NEWSLETTER:
      return {
        ...state,
        newsletter: {
          email: null,
          error: null,
          loading: false
        }
      };
    default:
      return state;
  }
}

export default newsletter;
