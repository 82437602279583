import React from "react";
import { withNamespaces } from "react-i18next";

// Import style
import { StyledLoaderData } from "./style";

const LoaderData = () => {
  return (
    <StyledLoaderData>
      <div>
        <div />
        <div />
        <div />
        <div />
      </div>
    </StyledLoaderData>
  );
};

export default withNamespaces("translations")(LoaderData);
