import axios from "axios";
import API from "../api";

export async function getCategories(urlApi) {
  return new Promise(resolve => {
    const request = axios.get(`${urlApi}${API.categories.all}`);
    return request
      .then(res => {
        // console.log('RESULT', res);
        resolve(res.data);
      })
      .catch(err => console.log(err));
  });
}
