const colors = mainTheme => {
  return {
    color: mainTheme.regular
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: 1.5
  };
};

const commonQuoteMainStyle = (mainTheme, mainFont) => {
  return {
    ...colors(mainTheme),
    ...font(mainFont),
    fontStyle: "italic"
  };
};

const commonQuoteChildrenStyle = () => {
  return {
    footer: {
      fontStyle: "normal"
    }
  };
};

const defaultQuoteCSSProperties = (mainTheme, mainFont) => {
  return {
    short: {
      main: {
        ...commonQuoteMainStyle(mainTheme, mainFont),
        fontSize: "3.6rem",
        responsive: {
          mobile: {
            fontSize: "2.5rem",
            lineHeight: 1.8,
            maxWidth: "400px"
          },
          tablet: {
            fontSize: "3rem",
            lineHeight: 1.65,
            maxWidth: "500px"
          },
          desktop: {
            maxWidth: "600px"
          },
          lgDesktop: {
            maxWidth: "600px"
          }
        }
      },
      children: {
        ...commonQuoteChildrenStyle(),
        footer: {
          fontSize: "1.75rem"
        },
        "a>span>cite:before": {
          marginRight: "30px"
        }
      }
    },
    long: {
      main: {
        ...commonQuoteMainStyle(mainTheme, mainFont),
        fontSize: "1.6rem",
        lineHeight: "1.75"
      },
      children: {
        ...commonQuoteChildrenStyle(),
        footer: {
          fontSize: "1.25rem"
        },
        "a>span>cite:before": {
          marginRight: "15px"
        }
      }
    }
  };
};

export default defaultQuoteCSSProperties;
