import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router";
import ReactEventOutside from "react-event-outside";
import { Search } from "styled-icons/fa-solid/Search";
import { Close } from "styled-icons/material/Close";
import { Spring } from "react-spring";

import LinkButton from "../link-button";

import { SearchBarStyled } from "./style";

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInputHidden: typeof props.full === "undefined",
      buttonType: "button",
      searchValue: props.searchContent || ""
    };

    this.inputFocus = this.inputFocus.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.resetSearchValue = this.resetSearchValue.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.toggleSearchBar = this.toggleSearchBar.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.inputRef = React.createRef();
  }

  toggleSearchBar() {
    if (typeof this.props.full === "undefined") {
      this.setState({
        isInputHidden: !this.state.isInputHidden
      });
    }
    if (this.state.isInputHidden) {
      this.inputRef.current.focus();
    } else {
      this.inputRef.current.blur();
    }
  }

  //Les 4 fonctions suivantes sont dédiées à la gestion du clic à l'extérieur de la SearchBar et ont été copiées depuis StackOverflow
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.state.isInputHidden &&
      !this.props.full
    ) {
      this.setState({ searchValue: "" });
      this.toggleSearchBar();
    }
  }

  onSearch = e => {
    e.preventDefault();
    const { searchValue } = this.state;
    if (searchValue.length > 0) {
      this.props.history.push({
        pathname: "/recherche",
        search: `?q=${searchValue}`
      });
      this.toggleSearchBar();
      this.setState({ searchValue: "" });
    }
  };

  handleChangeSearch = e => {
    this.setState({ searchValue: e.target.value });
  };

  resetSearchValue = () => {
    if (this.state.searchValue.length > 0) {
      this.setState({ searchValue: "" });
      this.inputRef.current.focus();
    } else this.toggleSearchBar();
  };

  handleSearchClick = e => {
    if (this.state.searchValue.length > 0) {
      this.onSearch(e);
    } else {
      this.toggleSearchBar();
    }
  };

  inputFocus = () => {
    this.state.isInputHidden
      ? this.inputRef.current.focus()
      : this.inputRef.current.blur();
  };

  render() {
    const { full } = this.props;
    const searchBarSpringProps = () => {
      const springProps = {
        from: {
          border: "0px solid transparent"
        },
        to: {
          border: "0px solid transparent"
        }
      };
      if (!full) {
        springProps.from.border = "2px solid transparent";
        springProps.to.border = `2px solid ${
          this.state.isInputHidden || full ? "transparent" : "#4ED6CB"
        }`;
      }
      return springProps;
    };
    const inputSpringProps = () => {
      const springProps = {
        from: {},
        to: {}
      };
      springProps.from.color = "#fff";
      springProps.to.color = this.state.isInputHidden
        ? "#fff"
        : full
          ? "rgb(29,63,79)"
          : "#4ED6CB";
      if (!full) {
        springProps.from.width = 0;
        springProps.to.width = this.state.isInputHidden ? 0 : "auto";
      }
      return springProps;
    };
    return (
      <Spring from={searchBarSpringProps().from} to={searchBarSpringProps().to}>
        {props => (
          <SearchBarStyled
            onSubmit={this.onSearch}
            action={"/recherche"}
            isInputHidden={this.state.isInputHidden}
            searchValue={this.state.searchValue}
            style={props}
            ref={this.setWrapperRef}
            full={full}
          >
            <div className="reset-cta" onClick={this.resetSearchValue}>
              <LinkButton noMargin styletype="button">
                <Close size={30} />
              </LinkButton>
            </div>
            <Spring from={inputSpringProps().from} to={inputSpringProps().to}>
              {props => (
                <div className="input-container">
                  <input
                    ref={this.inputRef}
                    style={props}
                    type="text"
                    placeholder={this.props.t("search.placeholder")}
                    onChange={this.handleChangeSearch}
                    value={this.state.searchValue}
                  />
                </div>
              )}
            </Spring>
            <div className="search-cta" onClick={this.inputFocus}>
              <button onClick={this.handleSearchClick} type="button">
                <Search size={30} />
              </button>
            </div>
          </SearchBarStyled>
        )}
      </Spring>
    );
  }
}

export default withRouter(
  withNamespaces("translations")(ReactEventOutside()(SearchBar))
);
