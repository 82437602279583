import styled, { css } from "styled-components";
// import { mappingThemeStyle } from "@bit/baloonit.main.helpers.helpers";
import { mappingThemeStyle } from "../../helpers";
import { titleProptypes } from "./proptypes";

const allowedStyleProps = {
  main: {
    normal: [
      "color",
      "fontFamily",
      "fontVariant",
      "fontSize",
      "lineHeight",
      "marginBottom"
    ],
    responsive: {
      mobile: ["fontSize"],
      tablet: ["fontSize"],
      desktop: ["fontSize"],
      lgDesktop: ["fontSize"]
    }
  }
};

const defaultStyleFromTheme = itemName => {
  return css`
    ${props => mappingThemeStyle(itemName, props.theme, allowedStyleProps)};
    ${props => (props.noMargin ? "margin-bottom: 0 !important;" : "")};
    text-align: ${props =>
      props.textAlign === "left" ||
      props.textAlign === "center" ||
      props.textAlign === "right"
        ? props.textAlign
        : "left"};
    width: 100%;
  `;
};

const titleMainClassName = "title";

export const TitleLv1 = styled.h1.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv1")};
  > a {
    line-height: initial !important;
  }
`;
TitleLv1.propTypes = titleProptypes;

export const TitleLv2 = styled.h2.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv2")};
`;
TitleLv2.propTypes = titleProptypes;

export const TitleLv3 = styled.h3.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv3")};
`;
TitleLv3.propTypes = titleProptypes;

export const TitleLv4 = styled.h4.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv4")};
`;
TitleLv4.propTypes = titleProptypes;

export const TitleLv5 = styled.h5.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv5")};
`;
TitleLv5.propTypes = titleProptypes;

export const TitleLv6 = styled.h6.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme("titles.lv6")};
`;
TitleLv6.propTypes = titleProptypes;
