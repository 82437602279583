import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";

// Import components
import SearchBar from "../search-bar";
import WrapperContent from "../wrapper-content";
import Logo from "../../components/logo";

// Import style
import { HeaderWrapper } from "./style";

class Header extends Component {
  render() {
    return (
      <HeaderWrapper id="header">
        <WrapperContent>
          <div>
            <Logo />
          </div>
          <SearchBar />
        </WrapperContent>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  titleContent: PropTypes.string
};

export default withNamespaces("translations")(Header);
