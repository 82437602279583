import { call, all, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";

import * as voteService from "./services";

export function* createVote({ urlApi, articleId, voteId, emotionId }) {
  try {
    const vote = yield call(
      voteService.create,
      urlApi,
      articleId,
      voteId,
      emotionId
    );
    yield put({
      type: a.CREATE_VOTE.SUCCESS,
      payload: vote
    });
  } catch (e) {
    yield put({
      type: a.CREATE_VOTE.FAILURE,
      payload: e.message
    });
  }
}

/* WATCHERS */
export function* watchCreateVote() {
  yield takeLatest(a.CREATE_VOTE.REQUEST, createVote);
}

// export default all([takeLatest(a.CREATE_VOTE.REQUEST, createVote)]);
