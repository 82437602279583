import styled, { css } from "styled-components";
// import { mappingThemeStyle } from "@bit/baloonit.main.helpers.helpers";
import { mappingThemeStyle } from "../../helpers";
import PropTypes from "prop-types";

const allowedStyleProps = {
  main: {
    normal: ["color", "fontFamily", "fontVariant", "fontSize", "lineHeight"]
  }
};

const defaultStyleFromTheme = itemName => {
  return css`
    ${props => mappingThemeStyle(itemName, props.theme, allowedStyleProps)};
    margin-bottom: ${props => (props.noMargin ? 0 : "20px")};
    text-align: ${props =>
      props.textAlign === "left" ||
      props.textAlign === "center" ||
      props.textAlign === "right" ||
      props.textAlign === "justify"
        ? props.textAlign
        : "left"};
  `;
};

export const Paragraph = styled.p.attrs({
  className: "paragraph"
})`
  ${defaultStyleFromTheme("paragraph")};
`;

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  noMargin: PropTypes.bool,
  textAlign: PropTypes.string
};

export default Paragraph;
