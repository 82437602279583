const colors = mainTheme => {
  return {
    color: mainTheme.light
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: 1.25
  };
};

const fontCategoryLink = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: 1
  };
};

const defaultArticleListItemCSSProperties = (mainTheme, mainFont) => {
  return {
    titleListItem: {
      main: {
        color: mainTheme.ultralight,
        ...font(mainFont),
        fontVariant: mainFont.variants.bold,
        fontSize: "2.5rem"
      }
    },
    categoryLink: {
      main: {
        ...colors(mainTheme),
        ...fontCategoryLink(mainFont),
        fontSize: "1.4rem",
        textDecoration: "none",
        textTransform: "uppercase",
        marginBottom: "2px"
      }
    }
  };
};

export default defaultArticleListItemCSSProperties;
