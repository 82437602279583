import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.themeColors.dark};
  font-family: ${props => props.theme.fonts.main.name};
  position: relative;
  z-index: 50;
  .contact-us {
    color: ${props => props.theme.colors.themeColors.light};
    text-decoration: none;
  }
  .institutionnel {
    align-items: center;
    border-top: 1px solid ${props => props.theme.colors.themeColors.light};
    display: flex;
    padding-top: 15px;
    * {
      color: ${props => props.theme.colors.themeColors.light};
      font-size: 1.2rem;
      transition: 250ms;
    }
    a span {
      &:hover {
        color: ${props => props.theme.colors.themeColors.white};
      }
    }
    nav {
      ul {
        align-items: center;
        display: flex;
        li {
          align-items: center;
          display: flex;
          &:not(:first-of-type) {
            &:before {
              content: "|";
              margin-left: 6px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  .right {
    margin-left: auto;
  }
  .contents {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    width: 100%;
    > div {
      &:first-of-type {
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        flex: 4 4 0;
        max-width: 60%;
        p,
        div {
          color: ${props => props.theme.colors.themeColors.white};
        }
        > div {
          &:first-of-type {
            margin-right: 50px;
          }
          &:last-of-type {
            display: flex;
            flex: 4 4 0;
            flex-direction: column;
            .paragraph,
            .paragraph > * {
              font-size: 1.4rem;
            }
          }
        }
      }
      &:last-of-type {
        margin: auto;
      }
    }
    .category-list {
      ${breakpoint("tablet")`
			padding-left:30px;
		`};

      .title {
        font-size: 2rem;
        font-weight: ${props => props.theme.fonts.main.variants.bold};
      }
    }
  }
  ${breakpoint("mobile", "tablet")`
    .wrapper-content {
      width: 100%;
    }
    .institutionnel {
      display: block;
      nav ul {
        display: block;
        width: 100%;
        li {
          display: block;
          margin-bottom: 7px;
          width: inherit;
          &:not(:first-of-type):before {
            content: none;
          }
        }
      }
      ul li,
      > p {
        text-align: center;
      }
    }
    .contents > * {
      width: 100%;
    }
    .contents > div:first-of-type {
      max-width: 100%;
      > div {
        align-items:end;
        margin-bottom: 15px;
        margin-right: 0 !important;
        width: 115px;
        &:last-of-type {
        	flex: 0 auto;
        	width: 100%;
        }
      }
      > p {
          flex: 0 auto;
          width: 100%;
        }
      }
    }
  `};
  ${breakpoint("tablet", "desktop")`
    .wrapper-content {
      padding:40px 60px 20px;
      width: 100%;
    }
    .contents > div:first-of-type > div:first-of-type {
    	margin-bottom: 20px;
    }
  `};
`;
