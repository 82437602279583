export const CREATE_NEWSLETTER = {
  REQUEST: "newsletters/CREATE/REQUEST",
  SUCCESS: "newsletters/CREATE/SUCCESS",
  FAILURE: "newsletters/CREATE/FAILURE"
};
export const RESET_NEW_NEWSLETTER = "newsletters/RESET";

export function createNewsletter(urlApi, email) {
  return {
    type: CREATE_NEWSLETTER.REQUEST,
    urlApi,
    email
  };
}

export function resetNewsletter() {
  return {
    type: RESET_NEW_NEWSLETTER
  };
}
