import styled, { css } from "styled-components";
import Title from "../title";

const titleLabeled = css`
  align-items: center;
  background-color: ${props =>
    props.reverseTheme
      ? props.theme.colors.themeColors.white
      : props.theme.colors.themeColors.dark};
  border-radius: 35px 0 35px 35px;
  display: flex;
  flex-flow: column wrap;
  height: 24px;
  justify-content: center;
  margin-left: ${props => (props.reverseTheme ? "0" : "10px")};
  margin-top: ${props => (props.reverseTheme ? "6px" : "0")};
  line-height: 1;
  > span {
    color: ${props =>
      props.reverseTheme
        ? props.theme.colors.themeColors.dark
        : props.theme.colors.themeColors.white};
    font-size: 1.5rem;
    font-weight: 700;
    line-height: inherit !important;
    padding: 0 15px;
  }
`;

export const StyledLogo = styled(Title)`
  font-size: initial;
  line-height: 1;
  > a {
    color: ${props =>
      props.reverseTheme
        ? props.theme.colors.themeColors.white
        : props.theme.colors.themeColors.dark};
    > span {
      display: flex;
      flex-flow: ${props => (props.reverseTheme ? "column" : "row")} wrap;
      align-items: center;
      text-transform: uppercase;
      > span {
        &:first-of-type {
          ${props =>
            props.reverseTheme
              ? `color: ${props.theme.colors.themeColors.white};`
              : null};
          font-size: ${props => (props.reverseTheme ? "2.6" : "2.1")}rem;
          font-weight: 700;
        }
        &:last-of-type {
          ${titleLabeled};
        }
      }
    }
  }
`;
