import articlesEndpoints from "../store/article/endpoints";
import categoriesEndpoints from "../store/category/endpoints";
import votesEndpoints from "../store/vote/endpoints";
import newslettersEndpoints from "./newsletter/endpoints";

// Add imports to API variable
const api = {
  articles: articlesEndpoints(),
  categories: categoriesEndpoints(),
  votes: votesEndpoints(),
  newsletters: newslettersEndpoints()
};

export default api;
