import React from "react";
import { withNamespaces } from "react-i18next";
import LinkButton from "../../components/link-button";

// Import style
import { StyledLogo } from "./style";

const Logo = props => {
  const { t, ...rest } = props;
  return (
    <StyledLogo noMargin {...rest}>
      <LinkButton styletype="link" nostyle to={"/"}>
        <span>{t("logoText.main")}</span>
        <span>
          <span> {t("logoText.alt")}</span>
        </span>
      </LinkButton>
    </StyledLogo>
  );
};

export default withNamespaces("translations")(Logo);
