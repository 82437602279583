import axios from "axios";
import qs from "qs";
import API from "../api";

export async function create(urlApi, email) {
  return new Promise(resolve => {
    const url = `${urlApi}${API.newsletters.one}`;
    const data = qs.stringify({
      email: email
    });
    const request = axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        // console.log('RESULT', res);
        resolve(res.data);
      })
      .catch(err => console.log(err));
  });
}
