import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { Frontload } from "react-frontload";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import createStore from "./app/store/configureStore";
import mainSaga from "./app/store/sagas";

import canUseMatomo from "./app/helpers/matomo/can-use";

import App from "./app/app";
import i18n from "./app/i18n"; // initialized i18next instance
import { HelmetProvider } from "react-helmet-async";

// Import style
import theme from "./app/theme-style";

// Create a store and get back itself and its history object
// const { store, history } = createStore();
const { store, history } = createStore();
store.runSaga(mainSaga);

let matomoLib;
let matomoStats;

if (canUseMatomo) {
  const matomoEnvSiteId = `REACT_APP_MATOMO_SITE_ID`;
  matomoLib = require("react-piwik");
  matomoStats = new matomoLib({
    url: canUseMatomo ? process.env.REACT_APP_MATOMO_URL : "",
    siteId: canUseMatomo ? process.env[matomoEnvSiteId] : 0,
    trackErrors: true,
    jsFilename: "matomo.js",
    phpFilename: "matomo.php"
  });
}

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <HelmetProvider>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter
            history={
              canUseMatomo ? matomoStats.connectToHistory(history) : history
            }
          >
            <Frontload noServerRender={true}>
              <App />
            </Frontload>
          </ConnectedRouter>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </HelmetProvider>
);

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
