import styled from "styled-components";
import PageScrollTop from "../../layouts/page-scroll-top";
import { bodyContentStyle } from "../article/style";

export const StyledSimpleContentPage = styled(PageScrollTop)`
  section {
    .wrapper-content {
      article {
        padding-top: 5px;
      }
    }
    ${bodyContentStyle};
  }
`;
