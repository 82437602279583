import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledInput = styled.input.attrs({
  type: props => props.modelType || "text"
})`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: ${props => props.theme.colors.themeColors.regular};
  font-size: 14px;
  padding: 0 10px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${props => props.theme.colors.themeColors.dark};
  }
`;

const Input = props => {
  return <StyledInput {...props} />;
};

Input.propTypes = {
  modelType: PropTypes.string
};

export default Input;
