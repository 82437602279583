const linkColors = mainTheme => {
  return {
    color: mainTheme.regular
  };
};

const buttonColors = mainTheme => {
  return {
    color: mainTheme.white
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: "20px"
  };
};

const defaultLinkButtonCSSProperties = (mainTheme, mainFont) => {
  return {
    link: {
      main: {
        ...linkColors(mainTheme),
        ...font(mainFont)
      }
    },
    button: {
      main: {
        ...buttonColors(mainTheme),
        ...font(mainFont)
      }
    }
  };
};

export default defaultLinkButtonCSSProperties;
