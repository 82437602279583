import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import "react-id-swiper/src/styles/css/swiper.css";

const MainDefaultStyle = createGlobalStyle`
  ${reset}
  html { font-size: 62.5%; }
  button {
    margin: 0;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background: none;
  }
  input,
 	input:focus,
 	button,
 	button:focus {
		outline:none;
  }
  main {  	
  	min-height: 100vh;
  	overflow: hidden;
  	display: flex;
    flex-flow: column wrap;
    > div:not(.cookie-bar) {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column wrap;
      > div {
        flex: 1 1 0;
        margin-top: 68px;
      }
    }
    footer {
      margin-top: auto;
    }
  }
`;

export default MainDefaultStyle;
