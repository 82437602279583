import React from "react";
import Paragraph from "../paragraph";

const ParagraphBreaks = props => {
  const textSplit = props.text.split("\n");
  return textSplit.map((paragraphContent, index) => {
    if (index !== textSplit.length - 1) {
      return (
        <Paragraph noMargin key={index}>
          {paragraphContent}
        </Paragraph>
      );
    } else {
      return (
        <Paragraph noMargin={props.noMargin || false} key={index}>
          {paragraphContent}
        </Paragraph>
      );
    }
  });
};

export default ParagraphBreaks;
