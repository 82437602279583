import { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { endOfBreakpoint } from "../";

const getComponentProps = (knownComponents, componentPath) => {
  let path = knownComponents;
  componentPath.map(prop => {
    path = path[prop];
    return false;
  });
  return path;
};

const setCSSWriting = string => {
  const regex = new RegExp("[A-Z]");
  return string.replace(regex, match => {
    return "-" + match.toLowerCase();
  });
};

const fontVariantCase = fontVariant => {
  const italics = ["i", "italic"];
  const hasItalic = () => {
    return (
      typeof fontVariant.italic !== "undefined" ||
      italics.indexOf(fontVariant) > -1
    );
  };
  let fontVariantCSS = "";
  if (hasItalic()) {
    fontVariantCSS += "font-style:italic;";
  }
  fontVariantCSS += `font-weight:${parseInt(fontVariant, 10)};`;
  return fontVariantCSS;
};

const specialUseProperty = (prop, value) => {
  const cases = ["fontVariant", "responsive"];
  const needToRender =
    cases.indexOf(prop) > -1 &&
    (typeof value !== "string" || typeof value !== "number");
  let rewriteProp;
  let status = false;
  let rewriteValue = value;
  if (needToRender) {
    status = true;
    switch (prop) {
      case "fontVariant":
        rewriteValue = fontVariantCase(rewriteValue);
        break;
      default:
        break;
    }
  } else {
    rewriteProp = setCSSWriting(prop);
  }
  return {
    status,
    prop: rewriteProp,
    value: rewriteValue
  };
};

const getPropsForCSS = componentProps => {
  return Object.entries(componentProps).reduce((cssValues, value) => {
    const data = specialUseProperty(value[0], value[1]);
    return cssValues + `${data.status ? "" : `${data.prop}:`}${data.value};`;
  }, "");
};

const filteredProps = (componentProps, allowedStyleProps) => {
  return Object.keys(componentProps).reduce((newProps, value) => {
    if (allowedStyleProps.indexOf(value) > -1)
      newProps[value] = componentProps[value];
    return newProps;
  }, {});
};

const applyCss = (cases, allowedStyleProps) => {
  const filteredCss = filteredProps(cases, allowedStyleProps);
  return getPropsForCSS(filteredCss);
};

const applyNormalCss = (cases, allowedStyleProps, childSelector) => {
  const cssNormalContent = applyCss(cases, allowedStyleProps);
  return css`
    ${typeof childSelector !== "undefined" ? childSelector : ""} {
      ${cssNormalContent};
    }
  `;
};

const applyResponsiveCss = (
  cases,
  allowedStyleProps,
  childSelector,
  breakpointsFromTheme
) => {
  const mapRespCases = Object.entries(cases);
  return mapRespCases.map(respCase => {
    const cssRespState =
      Object.keys(respCase[1]).indexOf("state") > -1
        ? applyStateCss(
            respCase[1].state,
            allowedStyleProps,
            childSelector,
            true
          )
        : "";
    const cssRespContent = applyCss(
      cases[respCase[0]],
      allowedStyleProps.responsive[respCase[0]]
    );
    const needStateCss = typeof cssRespState[0] !== "undefined";
    const isFromChild = typeof childSelector !== "undefined";

    const lastBreakpoint = endOfBreakpoint(respCase[0], breakpointsFromTheme);

    if (Object.keys(breakpointsFromTheme).indexOf(respCase[0]) === 3)
      return css`
        ${isFromChild ? childSelector : ""} {
          ${breakpoint(respCase[0])`
            ${cssRespContent}; 
            ${needStateCss ? cssRespState[0][0] : ""}{
              ${needStateCss ? cssRespState[0][1] : ""}
						};
	                               
          `};
        }
      `;

    return css`
      ${isFromChild ? childSelector : ""} {
        ${breakpoint(respCase[0], lastBreakpoint)`        
	        ${cssRespContent};         
	         ${needStateCss ? cssRespState[0][0] : ""}{
	            ${needStateCss ? cssRespState[0][1] : ""}
						};
        `};
      }
    `;
  });
};

const applyStateCss = (cases, allowedStyleProps, childSelector, fromResp) => {
  const mapStateCases = Object.entries(cases);
  return mapStateCases.map((stateCase, index) => {
    let cssStateContent = applyCss(
      cases[stateCase[0]],
      allowedStyleProps.state[stateCase[0]]
    );
    cssStateContent = fromResp
      ? cssStateContent.replace(";", " !important;")
      : cssStateContent;
    return fromResp
      ? ["&:" + stateCase[index], cssStateContent]
      : css`
          ${typeof childSelector !== "undefined" ? childSelector : ""} {
            ${"&:" + stateCase[index]} {
              ${cssStateContent};
            }
          }
        `;
  });
};

const mappingThemeStyle = (itemName, mainTheme, allowedStyleProps) => {
  const elAccessThemeProps = itemName.split(".");
  const componentProps = getComponentProps(
    mainTheme.components,
    elAccessThemeProps
  );

  const hasChildren = Object.keys(componentProps).indexOf("children") > -1;

  const cssTreatment = (cases, allowedStyleProps, selector) => {
    const needNormal = Object.keys(allowedStyleProps).indexOf("normal") > -1;
    const needResponsive = Object.keys(cases).indexOf("responsive") > -1;
    const needState = Object.keys(cases).indexOf("state") > -1;

    return css`
      ${needNormal &&
        applyNormalCss(cases, allowedStyleProps.normal, selector)};
      ${needResponsive &&
        applyResponsiveCss(
          cases.responsive,
          allowedStyleProps,
          selector,
          mainTheme.breakpoints
        )};
      ${needState && applyStateCss(cases.state, allowedStyleProps, selector)};
    `;
  };

  return css`
    ${cssTreatment(componentProps.main, allowedStyleProps.main)};
    ${hasChildren
      ? Object.keys(componentProps.children).map(selector => {
          return cssTreatment(
            componentProps.children[selector],
            allowedStyleProps.children[selector],
            selector
          );
        })
      : ""};
  `;
};

export default mappingThemeStyle;
