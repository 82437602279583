import React, { Component } from "react";
import Title from "../title";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";

import { getCategories as getCategoriesAction } from "../../store/category/actions";

import LinkButton from "../../components/link-button";
import { StyledCategoryListWrapper, StyledCategoryList } from "./style";
import { getConfiguration } from "../../store/configuration/selectors";
import {
  getCategories,
  isCategoriesLoading
} from "../../store/category/selectors";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";

const List = props => {
  const { items } = props;
  return (
    <StyledCategoryList>
      {items.map((item, index) => (
        <li key={index}>
          <LinkButton
            styletype="link"
            nostyle
            to={`/categorie/${item.id}-${item.slug}`}
          >
            {item.libelle}
          </LinkButton>
        </li>
      ))}
    </StyledCategoryList>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCategoriesAction
    },
    dispatch
  )
});

const mapStateToProps = state => ({
  configuration: getConfiguration(state),
  categories: getCategories(state),
  loading: isCategoriesLoading(state)
});

class CategoryList extends Component {
  componentWillMount() {
    const { urlApi } = this.props.configuration;
    this.props.actions.getCategoriesAction(urlApi);
  }

  renderCategoryList() {
    const { config, categories, loading } = this.props;
    const hasTitle = typeof config.titleContent !== "undefined";
    return (
      <StyledCategoryListWrapper>
        {hasTitle && (
          <Title titleType={config.titleType || 2}>{config.titleContent}</Title>
        )}
        {!loading && categories.length > 0 && <List items={categories} />}
      </StyledCategoryListWrapper>
    );
  }

  render() {
    return <div>{this.renderCategoryList()}</div>;
  }
}

export default withRouter(
  compose(
    withNamespaces("translations"),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(CategoryList)
);
