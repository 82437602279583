import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledPage = styled.div`
  width: 100%;
  ${props =>
    props.bgColor
      ? `background-color: ${props.theme.layouts.page.bgColor}`
      : ""};
  > div {
    width: 100%; /*
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;   */
  }
  ${breakpoint("mobile", "tablet")`
  	> div {
      margin: 0 auto;
      max-width: 100%;
      width: 100%;
    }	
  `};
  ${breakpoint("tablet", "desktop")`
  	> div {
  	  max-width: 980px;
    }
  `};
`;

export const StyledPageNoTopMargin = styled(StyledPage)`
  > div {
    padding-top: 0;
  }
`;
