import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { mappingThemeStyle } from "../../helpers/";

const commonAllowedStyle = {
  main: {
    normal: [
      "color",
      "fontFamily",
      "fontVariant",
      "fontSize",
      "lineHeight",
      "marginBottom"
    ]
  }
};

const allowedStylePropsLink = commonAllowedStyle;

const allowedStylePropsButton = commonAllowedStyle;
allowedStylePropsButton.main.normal.push("backgroundColor");

const defaultStyleFromTheme = (itemName, isLink) => {
  return css`
    ${props =>
      mappingThemeStyle(
        itemName,
        props.theme,
        isLink ? allowedStylePropsLink : allowedStylePropsButton
      )};
    ${mainLinkButtonStyle(isLink)};
  `;
};

const mainLinkButtonStyle = isLink => {
  return css`
    display: ${isLink ? "inline-" : ""}flex;
    align-items: center;
    text-transform: ${props =>
      props.uppercase === "true" ? "uppercase" : "none"};
  `;
};

const noStyleLink = css`
  text-decoration: none;
`;

const StyledLink = css`
  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: underline;
  }
  &:hover {
    color: ${props => props.theme.colors.themeColors.light};
  }
  &:focus,
  &:active {
    color: ${props => props.theme.colors.themeColors.dark};
  }
`;

const linkButtonClassName = "linkButton";

export const OutsideLink = styled.a.attrs({
  className: linkButtonClassName,
  href: props => props.linkTo,
  target: props => (props.targetBlank ? "_blank" : "_self")
})`
  ${defaultStyleFromTheme("linkButton.link", true)};
  ${props => (props.nostyle === "true" ? noStyleLink : StyledLink)};
`;

export const InternalLink = styled(Link).attrs({
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.link", true)};
  ${props => (props.nostyle === "true" ? noStyleLink : StyledLink)};
`;

export const RedirectButton = styled.button.attrs({
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.button")};
  cursor: pointer;
  border-radius: 50px;
  box-sizing: content-box;
  height: 20px;
  font-size: 1.2rem;
  transition: 0.2s;
`;

export const Button = styled.button.attrs({
  type: props => props.buttonType || "button",
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.button")};
  cursor: pointer;
  border-radius: 50px;
  box-sizing: content-box;
  height: 20px;
  font-size: 1.2rem;
  ${props => (props.noMargin ? "margin-bottom: 0" : "")};
  margin-bottom: ${props => (props.noMargin ? 0 : "15px")};
`;
