import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledWrapperContent = styled.div.attrs({
  className: "wrapper-content"
})`
  box-sizing: border-box;
  position: relative;
  width: 980px;
  padding: 40px ${props => props.theme.layouts.page.padding} 20px;
  ${breakpoint("mobile", "desktop")`
    width: 100%;
  `};
  ${breakpoint("desktop")`
     margin: 0 auto;
	`};
`;
