// The basics
import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

// Import main style
import MainDefaultStyle from "./../../src/app/theme-style/main-default-style";

import canUseMatomo from "./helpers/matomo/can-use";

// Import routing
import Routes from "./routes/index";

// Import styles

let matomoLib;
if (canUseMatomo) {
  matomoLib = require("react-piwik");
}

class App extends Component {
  componentDidMount() {
    if (canUseMatomo) {
      matomoLib.push(["trackPageView"]);
    }
  }

  render() {
    return (
      <div id="app">
        <MainDefaultStyle />
        <main id="content">
          {/*<Header />*/}
          <Routes />
          {/*<Footer />*/}
          {/*<CookieBar />*/}
        </main>
      </div>
    );
  }
}

export default withNamespaces("translations")(App);
