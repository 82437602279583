import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Page from "../page";

export const StyledPageScrollTop = styled(Page)`
  .scrollUpButton {
    background-color: ${props =>
      props.theme.colors.themeColors.light} !important;
    border-radius: 50%;
    position: fixed;
    bottom: -102%;
    right: 30px;
    text-align: center;
    transition: bottom 800ms ease;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    z-index: 50003;
    cursor: pointer;
    outline: none;
    color: ${props => props.theme.colors.themeColors.white};
    &-toggled {
      bottom: 30px;
      transition: bottom 550ms ease;
    }
    svg {
      color: inherit;
    }
  }
  ${breakpoint("mobile", "desktop")`
    .scrollUpButton {
      right: 10px;
      height: 30px;
      width: 30px;
      svg {
        height: 20px;
        width: 20px;
      }
      &-toggled {
        bottom: 10px;
      }
    }
  `} ${breakpoint("tablet", "lgDesktop")`
    .scrollUpButton {
      right: 15px;
      &-toggled {
        bottom: 15px;
      }
    }
  `};
`;
