import { call, all, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";

import * as categoryService from "./services";

function* getCategories({ urlApi }) {
  try {
    const categories = yield call(categoryService.getCategories, urlApi);
    yield put({
      type: a.FETCH_CATEGORIES.SUCCESS,
      payload: categories
    });
  } catch (e) {
    yield put({
      type: a.FETCH_CATEGORIES.FAILURE,
      payload: e.message
    });
  }
}

export function* watchGetCategories() {
  yield takeLatest(a.FETCH_CATEGORIES.REQUEST, getCategories);
}

