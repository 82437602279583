import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import PageScrollTop from "../../layouts/page-scroll-top";
import topBackground from "../../assets/article-top-bg.svg";

const boldAndEmStyles = css`
  em {
    font-weight: ${props => props.theme.fonts.main.variants.regular};
    font-style: italic;
  }
  strong {
    font-weight: ${props => props.theme.fonts.main.variants.bold};
  }
`;

export const bodyContentStyle = css`
  .bodyContent {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    .title {
      color: ${props => props.theme.colors.themeColors.regular};
      font-weight: ${props => props.theme.fonts.main.variants.bold};
      font-size: 2rem;
    }
    .paragraph .linkButton {
      color: ${props => props.theme.colors.themeColors.ultralight};
      font-weight: ${props => props.theme.fonts.main.variants.medium};
      &:hover {
        background-color: ${props => props.theme.colors.themeColors.light20};
      }
      line-height: inherit;
    }
    .list {
      box-sizing: border-box;
      padding: 0;
      width: 100%;
      word-wrap: break-word;
      ${breakpoint("tablet")`
				padding: 0 40px;
			`};
      ol,
      ul {
        box-sizing: border-box;
        color: ${props => props.theme.colors.themeColors.regular};
        display: block;
        list-style-type: none;
        margin-bottom: 30px;
        padding: 0;
        width: 100%;
        li {
          align-items: flex-start;
          display: flex;
          flex-direction: row;
          margin-bottom: 7px;
          margin-top: 20px;
          ${boldAndEmStyles};
          &:before {
            color: ${props => props.theme.colors.themeColors.light};
            margin: 0 20px 0 0;
          }
          span {
            line-height: 1.75;
            font-size: 1.6rem;
            width: auto;
          }
        }
      }
      ol {
        counter-reset: numberedList;
        li {
          counter-increment: numberedList;
          &:before {
            border-bottom: 4px solid
              ${props => props.theme.colors.themeColors.ultralight};
            box-sizing: border-box;
            content: counter(numberedList) ".";
            font-weight: ${props => props.theme.fonts.main.variants.bold};
            font-size: 2.5rem;
            line-height: 1.25;
            padding-bottom: 2.5px;
          }
        }
      }
      ul {
        li {
          &:before {
            line-height: 1;
            margin-top: 7.5px;
            content: "●";
          }
        }
      }
    }
  }
`;

export const StyledArticlePage = styled(PageScrollTop)`
  .articleScrollContainer {
    > div:first-of-type {
      box-sizing: border-box;
      min-height: 0 !important;
      ${breakpoint("mobile", "desktop")`
      	min-height: 64px !important;
      	padding-top: 7px;
			`};
    }
  }
  .article-content {
    position: relative;
    text-align: left;
    margin-bottom: 40px;
    ${breakpoint("desktop")`
			> .wrapper-content {
				padding-left: 95px;
				padding-right: 95px;
				padding-top: 25px;
			}
		`};

    .categoryName a {
      font-weight: ${props => props.theme.fonts.main.variants.bold};
      color: inherit;
      text-transform: uppercase;
      font-variant: small-caps;
      text-decoration: none;
    }

    .wrapper-content-article {
      display: flex;
      flex-flow: row wrap;
      margin-top: 40px;
      position: relative;
      .user-infos {
        ${breakpoint("desktop")`
				width: 210px;
			`};
        width: 100%;
      }
      .bodyContent {
        box-sizing: border-box;
        width: 100%;

        .paragraph {
          ${boldAndEmStyles};
        }

        ${breakpoint("desktop")`
        	width: calc(100% - 210px);
        `};
      }
    }

    &:before {
      background-color: ${props => props.theme.colors.themeColors.light};
      background-image: url(${topBackground});
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: auto 105%;
      content: "";
      height: 235px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      ${breakpoint("mobile", "desktop")`
      	background-position: 50% 50%;
      `};
      ${breakpoint("mobile", "tablet")`
      	background-position: 50% 100%;
				background-size: 125% auto;      	
      `};
      ${breakpoint("tablet", "desktop")`
				background-size: 105% auto;      	
      `};
      ${breakpoint("desktop")`
				height: 385px;
				.wrapper-content {
					padding-left: 100px;
					padding-right: 100px;
				}
			`};
    }

    .categoryName,
    .title {
      color: ${props => props.theme.colors.themeColors.white};
    }

    .main-article {
      height: 165px;
      width: 100%;
      ${breakpoint("tablet")`
	      height: 275px;
	    `};
      ${breakpoint("desktop")`
	      height: 420px;
	    `};
      img {
        object-fit: cover;
        //font-family: "object-fit: cover;";
        height: 100% !important;
        width: 100% !important;
      }
    }

    .articleInfos {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      margin-top: 20px;
      > div {
        &:last-of-type {
          margin-left: auto;
        }
      }
    }
    .social-sharing-sticky {
      transition: top 300ms ease-in;
      &.sticky {
        z-index: 49999;
      }
      ${breakpoint("mobile", "desktop")`
				&.sticky {
					background-color: ${props => props.theme.colors.themeColors.white};
					right: 0 !important;
					top: 68px !important;
					width: 100% !important;
					.social-sharing {
						margin-bottom: 15px;
						margin-top: 15px;
						padding: 0 20px;
					}
				}
			`};
      ${breakpoint("desktop")`
				position: absolute;
				right: -54px;
				top: -100px;
				&.sticky {
					right: calc(((100vw - 790px - (24px / 2)) / 2) - 54px) !important;
					top: 130px !important;
					width: auto !important;
				}
			`};
    }
    ${bodyContentStyle};
  }

  // Linked articles
  .linked-articles {
    position: relative;
    .wrapper-content {
      padding-top: 0;
    }
    .article-item {
      margin-bottom: 0;
      &:hover {
        box-shadow: none;
      }
      > div {
        padding-bottom: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    section {
      > div {
        .title {
          margin-bottom: 0;
        }
        .categoryLink,
        .title {
          color: ${props => props.theme.colors.themeColors.white};
        }
        .categoryLink {
          &:before {
            content: none;
          }
        }
      }
    }

    &:before {
      background-color: ${props => props.theme.colors.themeColors.light};
      content: "";
      display: block;
      height: calc(100% * 2 / 3);
      left: 0;
      position: absolute;
      top: calc(100% * 1 / 3);
      width: 100%;
      z-index: 0;
    }
    ${breakpoint("mobile", "desktop")`
      .wrapper-content {
        padding-right:0 !important;
        .article-item {
          margin-right: 20px;
          width: 80% !important;
        }
    `};
    ${breakpoint("tablet", "desktop")`
    	.wrapper-content {
    		.article-item {
          width: calc((100vw - 80px) * 3 / 7) !important;
        }
			}
		`};
    ${breakpoint("mobile", "tablet")`
    	padding-left: 20px;
    	width: calc(100vw - 20px);
    	.wrapper-content {
    		padding: 0;
    		.article-item {
    			margin-right: 0;
    		}
    	}
    `};
  }
`;
