import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledCategoryListWrapper = styled.div.attrs({
  className: "category-list"
})`
  .title {
    color: ${props => props.theme.colors.themeColors.white};
    text-transform: uppercase;
  }
  ${breakpoint("mobile", "tablet")`
    margin-top: 30px;
  `};
`;

export const StyledCategoryList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  width: 300px;
  font-size: 1.25rem;
  li {
    margin-bottom: 7px;
    margin-right: 7px;
    a {
      background-color: ${props => props.theme.colors.themeColors.regular};
      border-radius: 5px;
      color: ${props => props.theme.colors.themeColors.white};
      display: block;
      padding: 6px 12px;
      transition: background-color 350ms ease-in;
      &:hover {
        background-color: ${props => props.theme.colors.themeColors.white};
        color: ${props => props.theme.colors.themeColors.light};
      }
    }
  }
  ${breakpoint("mobile", "tablet")`
    width: 100%;
  `};
`;
