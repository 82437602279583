import React from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";

// Import main model page
// import PageCommonWrapper from "../layouts/page-common-wrapper";

// Import components
import PrivacyPolicy from "../containers/privacy-policy";
import Cgu from "../containers/cgu";
import NotFound from "../containers/not-found";
// import LoaderPage from "../components/loader-page";
// import { StyledHomepage } from "../containers/homepage/style";

const Homepage = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'homepage' */ "../containers/homepage/index"),
  loading: () =>
    null /*(
    <StyledHomepage bgColor id="homepage">
      <LoaderPage />
    </StyledHomepage>
  )*/,
  modules: ["homepage"]
});

const Article = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'homepage' */ "../containers/article/index"),
  loading: () =>
    null /*(
    <StyledHomepage bgColor id="homepage">
      <LoaderPage />
    </StyledHomepage>
  )*/,
  modules: ["article"]
});

const Category = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'homepage' */ "../containers/category/index"),
  loading: () =>
    null /*(
    <StyledHomepage bgColor id="homepage">
      <LoaderPage />
    </StyledHomepage>
  )*/,
  modules: ["category"]
});

const Search = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'homepage' */ "../containers/search/index"),
  loading: () =>
    null /*(
    <StyledHomepage bgColor id="homepage">
      <LoaderPage />
    </StyledHomepage>
  )*/,
  modules: ["search"]
});

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route exact path="/recherche" component={Search} />
    <Route exact path="/politique-confidentialite" component={PrivacyPolicy} />
    <Route exact path="/conditions-generales" component={Cgu} />
    <Route
      exact
      path="/categorie/:categoryId-:categorySlug"
      component={Category}
    />
    <Route exact path="/article/:articleId-:articleSlug" component={Article} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
