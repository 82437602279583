const endOfBreakpoint = (brkpt, breakpoints) => {
  const breakpointsKeys = Object.keys(breakpoints);
  const currentIndex = breakpointsKeys.findIndex(respCase => {
    return respCase === brkpt;
  });
  if (currentIndex === breakpointsKeys.length - 1) return null;
  return breakpointsKeys[currentIndex + 1];
};

export default endOfBreakpoint;
