import React from "react";
import ScrollUpButton from "react-scroll-up-button";
import { ArrowUpward } from "styled-icons/material/ArrowUpward";

// Import style layout
import { StyledPageScrollTop } from "./style";

const PageScrollTop = props => {
  return (
    <StyledPageScrollTop {...props}>
      {props.children}
      <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={250}
        ContainerClassName="scrollUpButton"
        TransitionClassName="scrollUpButton-toggled"
      >
        <ArrowUpward size={32} />
      </ScrollUpButton>
    </StyledPageScrollTop>
  );
};

export default PageScrollTop;
