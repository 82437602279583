export const CREATE_VOTE = {
  REQUEST: "votes/CREATE/REQUEST",
  SUCCESS: "votes/CREATE/SUCCESS",
  FAILURE: "votes/CREATE/FAILURE"
};
export const RESET_NEW_VOTE = "votes/RESET";

export function createVote(urlApi, articleId, voteId, emotionId) {
  // console.log(urlApi, articleId, voteId, emotionId);
  return {
    type: CREATE_VOTE.REQUEST,
    urlApi,
    articleId,
    voteId,
    emotionId
  };
}

export function resetVote() {
  return {
    type: RESET_NEW_VOTE
  };
}
