export const FETCH_CATEGORIES = {
  REQUEST: "categories/FETCH/REQUEST",
  SUCCESS: "categories/FETCH/SUCCESS",
  FAILURE: "categories/FETCH/FAILURE"
};
export const RESET_CATEGORIES = "categories/RESET";

export function getCategories(urlApi) {
  return {
    type: FETCH_CATEGORIES.REQUEST,
    urlApi
  };
}
