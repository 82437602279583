import React from "react";
// Import custom proptypes
import { titleProptypes } from "./proptypes";
import defaultTitleCSSProperties from "./cssDefaultProperties";

// Import styles
import {
  TitleLv1,
  TitleLv2,
  TitleLv3,
  TitleLv4,
  TitleLv5,
  TitleLv6
} from "./style";

export const defaultCSSProps = defaultTitleCSSProperties;

const Title = props => {
  const { titleType, children, ...rest } = props;
  const titleProps = () => {
    return {
      children: children,
      titleType: titleType ? titleType : 1,
      ...rest
    };
  };
  if (titleType === 2) return <TitleLv2 {...titleProps()} />;
  if (titleType === 3) return <TitleLv3 {...titleProps()} />;
  if (titleType === 4) return <TitleLv4 {...titleProps()} />;
  if (titleType === 5) return <TitleLv5 {...titleProps()} />;
  if (titleType === 6) return <TitleLv6 {...titleProps()} />;
  return <TitleLv1 {...titleProps()} />;
};

Title.propTypes = titleProptypes;

export default Title;
