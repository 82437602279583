import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Title from "../title";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";

//import Store
import { getConfiguration } from "../../store/configuration/selectors";
import {
  getNewsletter,
  isNewsletterLoading
} from "../../store/newsletter/selectors";
import {
  createNewsletter as createNewsletterAction,
  resetNewsletter
} from "../../store/newsletter/actions";

// Import components
import LinkButton from "../link-button";
import Input from "../input";
import Paragraph from "../paragraph";

// Import style
import { StyledNewsletterSignUp } from "./style";
import LoaderData from "../loader-data";

const mapStateToProps = state => ({
  configuration: getConfiguration(state),
  email: getNewsletter(state),
  loading: isNewsletterLoading(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createNewsletterAction,
      resetNewsletter
    },
    dispatch
  )
});

class NewsletterSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      hasSubmitted: false,
      isEmailFormatValid: true
    };

    this.emailSubmit = this.emailSubmit.bind(this);
    this.emailChange = this.emailChange.bind(this);
  }

  hasSubmittedEmail() {
    setTimeout(() => {
      this.setState({
        hasSubmitted: false
      });
    }, 7000);
    return (
      <Paragraph className="submitted" textAlign="center">
        {this.props.t("footer.signUp.thanks")}
      </Paragraph>
    );
  }

  emailChange = e => {
    this.setState({
      email: e.target.value,
      isEmailFormatValid: true
    });
  };

  emailSubmit = e => {
    e.preventDefault();
    const isEmailValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(
      this.state.email
    );
    if (isEmailValid) {
      this.setState({
        hasSubmitted: true,
        isEmailFormatValid: true
      });
      this.props.actions.resetNewsletter();
      this.props.actions.createNewsletterAction(
        this.props.configuration.urlApi,
        this.state.email
      );
      document.getElementById("signUpForm").reset();
    } else {
      this.setState({ isEmailFormatValid: false });
    }
  };

  renderSignUp = () => {
    const { t, loading } = this.props;
    if (loading)
      return (
        <div className="loaderSignUp">
          <LoaderData />
        </div>
      );
    return (
      <form onSubmit={e => this.emailSubmit(e)} id="signUpForm">
        <Input
          placeholder={t("footer.signUp.inputPlaceholder")}
          type="mail"
          name="email"
          value={this.state.value}
          onChange={e => this.emailChange(e)}
        />
        <LinkButton noMargin styletype="button" type="submit">
          {t("footer.signUp.buttonLabel")}
        </LinkButton>
      </form>
    );
  };

  render() {
    const { t, titleLv, loading } = this.props;
    return (
      <StyledNewsletterSignUp
        isEmailFormatValid={this.state.isEmailFormatValid}
      >
        <div>
          <Title textAlign="center" titleType={titleLv}>
            {t("footer.signUp.title")}
          </Title>
          {this.state.hasSubmitted && !loading && this.hasSubmittedEmail()}
          {this.renderSignUp()}
          {!this.state.isEmailFormatValid &&
            !loading && (
              <Paragraph noMargin className="warningEmailFormat">
                {t("footer.signUp.warningEmailFormat")}
              </Paragraph>
            )}
          <Paragraph noMargin>{t("footer.signUp.notReady")}</Paragraph>
        </div>
      </StyledNewsletterSignUp>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewsletterSignup);
