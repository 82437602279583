import * as a from "./actions";
require("dotenv").config();

const mainConfig = () => {
  const config = {
    loading: false,
    error: null,
    langue: "fr",
    urlApi: `${process.env.REACT_APP_API_URL}/api`,
    urlBase: process.env.REACT_APP_URL_BASE
  };
  return config;
};

const INITIAL_STATE = {
  configuration: { ...mainConfig() }
};

export function configuration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.SET_CONFIGURATION.REQUEST:
      return {
        ...state,
        loading: true,
        configuration: {}
      };
    case a.SET_CONFIGURATION.SUCCESS:
      console.log("SET_CONFIGURATION.SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        configuration: action.payload
      };
    case a.SET_CONFIGURATION.FAILURE:
      return {
        ...state,
        loading: false,
        configuration: {}
      };
    default:
      return state;
  }
}

export default configuration;
