import React, { Component } from "react";
import { Trans, withNamespaces } from "react-i18next";
import Paragraph from "../paragraph";

// Import components
import WrapperContent from "../../components/wrapper-content";
import CategoryList from "../../components/category-list";
import LinkButton from "../../components/link-button";
import Logo from "../../components/logo";
import NewsletterSignup from "../../components/newsletter-signup";

// Import style
import { StyledFooter } from "./style";

const copyrightYear = startYear => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (startYear !== currentYear && typeof startYear !== "undefined")
    return `${startYear} - ${currentYear}`;
  return currentYear;
};

class Footer extends Component {
  render() {
    const { t, categories } = this.props;
    return (
      <StyledFooter>
        <WrapperContent>
          <div>
            <NewsletterSignup titleLv={5} />
          </div>
          <div className="contents">
            <div>
              <div>
                <Logo reverseTheme />
              </div>
              <div>
                <Paragraph>{t("footer.mainText")}</Paragraph>
                <Paragraph noMargin>
                  <Trans i18nKey="footer.contactUs">
                    Text contact
                    <a
                      className="contact-us"
                      href={"mailto:contact@assurancepourtous.africa"}
                    >
                      contact link label
                    </a>
                  </Trans>
                </Paragraph>
              </div>
            </div>
            <CategoryList
              config={{
                titleType: 2,
                titleContent: t("category.categories")
              }}
            />
          </div>
          <div className="institutionnel">
            <nav>
              <ul>
                <li>
                  <LinkButton
                    styletype="link"
                    nostyle
                    to={t("footer.links.legal.url")}
                  >
                    {t("footer.links.legal.label")}
                  </LinkButton>
                </li>
                <li>
                  <LinkButton
                    styletype="link"
                    nostyle
                    to={t("footer.links.conditions.url")}
                  >
                    {t("footer.links.conditions.label")}
                  </LinkButton>
                </li>
              </ul>
            </nav>
            <Paragraph className="right" noMargin>
              &copy; {copyrightYear()} - {t("footer.allRightReserved")}
            </Paragraph>
          </div>
        </WrapperContent>
      </StyledFooter>
    );
  }
}

export default withNamespaces("translations")(Footer);
