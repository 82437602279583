import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const SearchBarStyled = styled.form.attrs({
  className: "search-bar"
})`
  vertical-align: middle;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  .input-container {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 40px;
  }
  input {
    border: none;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.main.name};
    font-size: 1.4rem;
    font-weight: ${props => props.theme.fonts.main.variants.bold};
    height: 40px;
    &::placeholder {
      font-weight: ${props => props.theme.fonts.main.variants.default};
    }
  }
  button {
    height: 30px;
    transform: scale(0.75);
    cursor: pointer;
    svg {
      color: ${props => props.theme.colors.themeColors.dark};
      margin: -1px 1px 1px -1px;
    }
  }
  .reset-cta,
  .search-cta {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reset-cta {
    button {
      display: ${props => (props.isInputHidden ? "none" : "inline-block")};
    }
  }

  ${breakpoint("mobile", "desktop")`
    border-radius: 5px;
  `};
  ${breakpoint("desktop")`
		position: static;
		top:initial;
		right:initial;
  `};

  // Style for mini component
  ${props =>
    !props.full &&
    css`
      background-color: ${props => props.theme.colors.themeColors.white};
      border-radius: 5px;
      position: absolute;
      top: 12px;
      right: 12px;
    `};

  // Style for full component
  ${props =>
    props.full &&
    css`
      .input-container {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        box-sizing: border-box;
        padding-left: 10px;
        width: calc(100% - 90px);
        height: 50px;
        line-height: 50px;
        order: 1;
      }
      input {
        width: 100%;
      }
      .reset-cta,
      .search-cta {
        height: 50px;
      }
      .search-cta {
        background-color: ${props.theme.colors.themeColors.white};
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        order: 2;
        width: 60px;
      }
      .reset-cta {
        order: 3;
        justify-content: flex-end;
        width: 50px;
        svg {
          color: ${props => props.theme.colors.themeColors.white};
        }
      }

      ${breakpoint("mobile", "tablet")`
      background-color: ${props.theme.colors.themeColors.white};
      .search-cta {
        order: 3;
      }
      .input-container {
        border-radius: 0;
        padding-left: 5px;
        order: 2;
      }
      .reset-cta {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        order: 1;
        svg {
          color: ${props => props.theme.colors.themeColors.dark};
        }
      }
    `};
      ${breakpoint("mobile", "desktop")`
      .input-container {
        width: 100%;
      }
    `};
      ${breakpoint("tablet")`
      .input-container {
        background-color: ${props.theme.colors.themeColors.white};
        input {
          padding-left: 10px;
        }
      }
    `};
    `};
`;
