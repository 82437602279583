import React from "react";
import { withNamespaces } from "react-i18next";
import Title from "../../components/title";
import Paragraph from "../../components/paragraph";
import Landscape404Media from "../../components/landscape-404-media";

import { StyledNotFoundPage } from "./style";
import LinkButton from "../../components/link-button";
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import WrapperContent from "../../components/wrapper-content";

const NotFound = props => {
  const { t, siteURL, siteLangue } = props;
  return (
    <StyledNotFoundPage
      id="not-found"
      titlePage={t("notFound.metaTitle")}
      siteURL={siteURL}
      siteLangue={siteLangue}
      noCrawl
      bgColor
    >
      <ScrollToTopOnMount />
      <WrapperContent>
        <div>
          <Title noMargin textAlign="center" titleType={2}>
            {t("notFound.title")}
          </Title>
          <Paragraph textAlign="center" className="notFound-intro">
            {t("notFound.text")}
          </Paragraph>
          <Paragraph textAlign="center" className="notFound-link">
            <LinkButton styletype="link" nostyle to={t("notFound.link.url")}>
              {t("notFound.link.label")}
            </LinkButton>
          </Paragraph>
          <div className="svg-container">
            <Landscape404Media
              colors={{
                leaf: "#7C660F",
                background: "#e0bc00",
                trunk: "#ba931c",
                cloud: "#ffffff",
                leftPanel: "#00a89e",
                rightPanel: "#33687f",
                darkBg: "#0c2835"
              }}
            />
          </div>
        </div>
      </WrapperContent>
    </StyledNotFoundPage>
  );
};

export default withNamespaces("translations")(NotFound);
