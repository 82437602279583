import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
// Import bit components
import Title from "../../components/title";
import slug from "limax";

// Import layout
import { StyledSimpleContentPage } from "./style";

// Import components
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import WrapperContent from "../../components/wrapper-content";
import PageHeader from "../../components/page-header";

class SimpleContent extends Component {
  render() {
    const { t, siteURL, siteLangue, children, ...rest } = this.props;

    return (
      <StyledSimpleContentPage
        siteURL={siteURL}
        siteLangue={siteLangue}
        {...rest}
        id={slug(rest.titlePage)}
      >
        <ScrollToTopOnMount />
        <section>
          <PageHeader page="category">
            <Title noMargin textAlign="center" titleType={1}>
              {rest.titlePage}
            </Title>
          </PageHeader>
          <WrapperContent>
            <article className="bodyContent">{children}</article>
          </WrapperContent>
        </section>
      </StyledSimpleContentPage>
    );
  }
}

export default withNamespaces("translations")(SimpleContent);
