const colors = mainTheme => {
  return {
    color: mainTheme.black
  };
};

const font = mainFont => {
  return {
    fontFamily: mainFont.name,
    fontVariant: mainFont.variants.medium,
    lineHeight: 1.25
  };
};

const defaultContentImageCSSProperties = (mainTheme, mainFont) => {
  return {
    main: {
      ...colors(mainTheme),
      ...font(mainFont),
      fontSize: "2.5rem",
      margin: "0 0 40px 40px"
    }
  };
};

export default defaultContentImageCSSProperties;
