import React, { Component } from "react";
import { withRouter } from "react-router";
// import Helmet from "react-helmet";
import Helmet from "react-helmet-async";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
// Import selectors
import { getConfiguration } from "../../store/configuration/selectors";
// Import page style
import { StyledPage } from "./style";
// import { withTheme } from "styled-components";

// Import components
import Header from "../../components/header";
import Footer from "../../components/footer";
import CookieBar from "../../components/cookie-bar";
// import Routes from "../../app";

const mapStateToProps = state => ({
  configuration: getConfiguration(state)
});

class Page extends Component {
  getMetaTags(
    {
      titlePage,
      description,
      image,
      contentType,
      twitter,
      noCrawl,
      published,
      updated,
      category,
      tags
    },
    pathname
  ) {
    const { t, configuration } = this.props;
    const { urlBase: siteURL } = configuration;
    const theTitle = titlePage
      ? (titlePage + t("config.defaultSep") + t("global.siteName")).substring(
          0,
          60
        )
      : t("global.siteName");
    const theDescription = description
      ? description.substring(0, 155)
      : t("global.description");
    const theImage = `${
      image ? image : `${siteURL}${t("social.defaultImageURL")}`
    }`;
    const metaTags = [
      { itemprop: "name", content: theTitle },
      { itemprop: "description", content: theDescription },
      { itemprop: "image", content: theImage },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "description", content: theDescription },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: theTitle },
      { name: "twitter:description", content: theDescription },
      { name: "twitter:image:src", content: theImage },
      { property: "og:title", content: theTitle },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: siteURL + pathname },
      { property: "og:image", content: theImage },
      { property: "og:description", content: theDescription },
      { property: "og:site_name", content: t("global.siteName") }
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }

    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }

    return metaTags;
  }

  render() {
    const {
      t,
      location,
      children,
      id,
      className,
      configuration,
      ...rest
    } = this.props;
    const { urlBase: siteURL, langue: siteLangue } = configuration;
    return (
      <StyledPage {...rest}>
        <Helmet
          htmlAttributes={{
            lang: siteLangue,
            itemscope: undefined,
            itemtype: `http://schema.org/${rest.schema || "WebPage"}`
          }}
          title={
            rest.titlePage
              ? rest.titlePage + t("config.defaultSep") + t("global.siteName")
              : t("global.siteName")
          }
          link={[
            {
              rel: "canonical",
              href: siteURL + location.pathname
            }
          ]}
          meta={this.getMetaTags(rest, location.pathname)}
        />
        <Header />
        <div id={id} className={className}>
          {children}
        </div>
        <Footer />
        <CookieBar />
      </StyledPage>
    );
    // return null;
  }
}

Page.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    null
  )
)(Page);
