// Imports
import cssTitleProps from "../../components/title/cssDefaultProperties.js";
import defaultParagraphCSSProperties from "./paragraph.js";
import defaultQuoteCSSProperties from "../../components/quote/cssDefaultProperties";
import defaultLinkButtonCSSProperties from "../../components/link-button/cssDefaultProperties";
import defaultContentImageCSSProperties from "../../components/content-image/cssDefaultProperties";
import defaultArticleListItemCSSProperties from "../../components/article-list-item/cssDefaultProperties";

const components = (mainColors, mainFont) => {
  return {
    titles: cssTitleProps(mainColors, mainFont),
    paragraph: defaultParagraphCSSProperties(mainColors, mainFont),
    quote: defaultQuoteCSSProperties(mainColors, mainFont),
    linkButton: defaultLinkButtonCSSProperties(mainColors, mainFont),
    contentImage: defaultContentImageCSSProperties(mainColors, mainFont),
    articleListItem: defaultArticleListItemCSSProperties(mainColors, mainFont)
  };
};
export default components;
