import React from "react";
import { Trans, withNamespaces } from "react-i18next";
import Paragraph from "../../components/paragraph";
import Title from "../../components/title";

// Import model page
import { StyledPrivacyPolicy } from "./style";

// Import components
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import ParagraphBreaks from "../../components/paragraph-breaks";
import LinkButton from "../../components/link-button";

const LegalMentions = props => {
  const { t } = props;
  return (
    <StyledPrivacyPolicy id="article" titlePage={t("privacyPolicy.title")}>
      <ScrollToTopOnMount />
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block1.paragraph1">
          Paragraph 1
          <LinkButton styletype="link" nostyle to={"/"}>
            https://www.assurancepourtous.africa
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block1.paragraph2")}</Paragraph>
      <Title titleType={2}>{t("privacyPolicy.contents.block2.title")}</Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph1")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph2")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph3")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle1")}
      </Title>
      <Paragraph noMargin>
        {t("privacyPolicy.contents.block2.paragraph4")}
      </Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph5")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle2")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph6")}</Paragraph>
      <Paragraph noMargin>
        {t("privacyPolicy.contents.block2.paragraph7")}
      </Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph8")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle3")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph9")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle4")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph10")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle5")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph11")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle6")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph12")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph13")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph14")}</Paragraph>
      <Paragraph noMargin>
        {t("privacyPolicy.contents.block2.paragraph15")}
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block2.paragraph16">
          <LinkButton
            styletype="link"
            nostyle
            to={
              "https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces"
            }
          >
            CNIL url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle7")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph17")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph18")}</Paragraph>
      <Paragraph noMargin>
        {t("privacyPolicy.contents.block2.paragraph19")}
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block2.paragraph20">
          <LinkButton
            styletype="link"
            nostyle
            to={
              "https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees"
            }
          >
            CNIL url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle8")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph21")}</Paragraph>
      <ParagraphBreaks
        noMargin
        text={t("privacyPolicy.contents.block2.paragraph22")}
      />
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block2.paragraph23">
          <LinkButton
            styletype="link"
            nostyle
            to={
              "https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet"
            }
          >
            CNIL url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle9")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph24")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle10")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph25")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block2.paragraph26")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block2.subTitle11")}
      </Title>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block2.paragraph27">
          Texte plainte
          <LinkButton
            styletype="link"
            nostyle
            to={"https://www.cnil.fr/fr/plaintes/internet"}
          >
            CNIL url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={2}>{t("privacyPolicy.contents.block3.title")}</Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph1")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block3.subTitle1")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph2")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph3")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph4")}</Paragraph>
      <Title titleType={3}>
        {t("privacyPolicy.contents.block3.subTitle2")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph5")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph6")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph7")}</Paragraph>
      <Title titleType={4}>
        {t("privacyPolicy.contents.block3.minSubTitle1")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph8")}</Paragraph>
      <Title titleType={4}>
        {t("privacyPolicy.contents.block3.minSubTitle2")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph9")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph10")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph11")}</Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph12">
          Hotjar text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://www.hotjar.com/legal/compliance/gdpr-commitment"}
          >
            Hotjar url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph13")}</Paragraph>
      <Paragraph noMargin>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph14">
          Facebook text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://fr-fr.facebook.com/policies/cookies/"}
          >
            Facebook url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph noMargin>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph15">
          LinkedIn text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://www.linkedin.com/legal/cookie-policy?_l=fr_FR"}
          >
            LinkedIn url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph noMargin>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph16">
          Twitter text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://support.twitter.com/articles/20170518#"}
          >
            Twitter url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph17">
          Youtube text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://support.google.com/youtube/answer/32050?hl=fr"}
          >
            Youtube url
          </LinkButton>
          <LinkButton
            styletype="link"
            nostyle
            to={"https://www.google.fr/intl/fr/policies/technologies/cookies/"}
          >
            Youtube url 2
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={4}>
        {t("privacyPolicy.contents.block3.minSubTitle3")}
      </Title>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph18")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph19")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph20")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph21")}</Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph22")}</Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph23">
          Chrome text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://support.google.com/chrome/answer/95647?hl=fr"}
          >
            Chrome url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph24">
          Firefox text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://support.mozilla.org/fr/kb/activer-desactiver-cookies"}
          >
            Firefox url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph25">
          IE text
          <LinkButton
            styletype="link"
            nostyle
            to={
              "https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
            }
          >
            IE url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph26">
          Opera text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://help.opera.com/en/latest/web-preferences/#cookies"}
          >
            Opera url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph27">
          Safari text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"}
          >
            Safari url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans i18nKey="privacyPolicy.contents.block3.paragraph28">
          CNIL text
          <LinkButton
            styletype="link"
            nostyle
            to={"https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser"}
          >
            CNIL url
          </LinkButton>
        </Trans>
      </Paragraph>
      <Paragraph>{t("privacyPolicy.contents.block3.paragraph29")}</Paragraph>
    </StyledPrivacyPolicy>
  );
};

export default withNamespaces("translations")(LegalMentions);
