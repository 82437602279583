import * as a from "./actions";

const INITIAL_VOTE_STATE = {
  vote: {
    id: null,
    error: null,
    loading: false
  }
};

export function vote(state = INITIAL_VOTE_STATE, action) {
  let error;
  switch (action.type) {
    case a.CREATE_VOTE.REQUEST:
      return {
        ...state,
        vote: {
          id: null,
          error: null,
          loading: true
        }
      };
    case a.CREATE_VOTE.SUCCESS:
      return {
        ...state,
        vote: {
          id: action.payload,
          error: null,
          loading: false
        }
      };
    case a.CREATE_VOTE.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        vote: {
          id: null,
          error: error,
          loading: true
        }
      };
    case a.RESET_NEW_VOTE:
      return {
        ...state,
        vote: {
          id: null,
          error: null,
          loading: false
        }
      };
    default:
      return state;
  }
}

export default vote;
