import { combineReducers } from "redux";

// Import required reducers.js
import configuration from "./configuration/reducers";
import article from "./article/reducers";
import category from "./category/reducers";
import vote from "./vote/reducers";
import newsletter from "./newsletter/reducers";

const rootReducer = combineReducers({
  configuration,
  article,
  category,
  vote,
  newsletter
});

export default rootReducer;
