import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.themeColors.white};
  box-shadow: 0 3px 15px 0 rgba(108, 122, 137, 0.2);
  box-sizing: border-box;
  height: 68px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50000;
  .wrapper-content {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 0 35px 0;
    ${breakpoint("mobile", "tablet")`
      padding: 0 ${props => props.theme.layouts.page.padding} 0;
    `};
    > div {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      &:last-of-type {
        padding-left: 10px;
      }
      &:last-of-type {
        margin-left: auto;
      }
    }
  }
`;
