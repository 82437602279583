// Import configs
import colors from "./colors";
import breakpoints from "./breakpoints";
import gutters from "./gutters";
import fonts from "./fonts";
import sizes from "./sizes";
import layouts from "./layouts";
import components from "./components";

const mainColors = colors.themeColors;
const mainFont = fonts.main;

const theme = {
  colors,
  breakpoints,
  gutters,
  fonts,
  sizes,
  layouts: layouts(),
  components: components(mainColors, mainFont)
};

export default theme;
