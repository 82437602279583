import axios from "axios";
import API from "../api";

export async function getArticles(urlApi, isHomepage, categoryId, search) {
  return new Promise(resolve => {
    let urlRequest;

    if (!isHomepage && categoryId && !search) {
      urlRequest = `${urlApi}${API.articles.byCategory(categoryId)}`;
    } else if (!isHomepage && search && !categoryId) {
      urlRequest = `${urlApi}${API.articles.bySearch(search)}`;
    } else if (isHomepage && !search && !categoryId) {
      urlRequest = `${urlApi}${API.articles.byHome}`;
    } else {
      urlRequest = `${urlApi}${API.articles.all}`;
    }
    const request = axios.get(urlRequest);
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => console.log(err));
  });
}

export async function getArticle(urlApi, articleId) {
  return new Promise(resolve => {
    const request = axios.get(`${urlApi}${API.articles.byId(articleId)}`);
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => console.log(err));
  });
}
