import * as a from "./actions";

const INITIAL_CATEGORY_STATE = {
  categoriesList: {
    categories: [],
    error: null,
    loading: false
  }
};

export function category(state = INITIAL_CATEGORY_STATE, action) {
  let error;
  switch (action.type) {
    case a.FETCH_CATEGORIES.REQUEST:
      return {
        ...state,
        categoriesList: {
          categories: [],
          error: null,
          loading: true
        }
      };
    case a.FETCH_CATEGORIES.SUCCESS:
      return {
        ...state,
        categoriesList: {
          categories: action.payload,
          error: null,
          loading: false
        }
      };
    case a.FETCH_CATEGORIES.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        categoriesList: {
          categories: [],
          error: error,
          loading: true
        }
      };
    case a.RESET_CATEGORIES:
      return {
        ...state,
        categoriesList: {
          categories: [],
          error: null,
          loading: false
        }
      };
    default:
      return state;
  }
}

export default category;
