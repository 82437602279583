export const FETCH_ARTICLES = {
  REQUEST: "articles/FETCH/REQUEST",
  SUCCESS: "articles/FETCH/SUCCESS",
  FAILURE: "articles/FETCH/FAILURE"
};
export const RESET_ARTICLES = "articles/RESET";

export const FETCH_ARTICLE = {
  REQUEST: "article/FETCH/REQUEST",
  SUCCESS: "article/FETCH/SUCCESS",
  FAILURE: "article/FETCH/FAILURE"
};
export const RESET_ACTIVE_ARTICLE = "article/RESET";

// Articles
export function resetArticles() {
  return {
    type: RESET_ARTICLES
  };
}

export function getArticles(urlApi, isHomepage, categoryId, search) {
  return {
    type: FETCH_ARTICLES.REQUEST,
    urlApi,
    isHomepage,
    categoryId,
    search
  };
}

// Article
export function resetCurrentArticle() {
  return {
    type: RESET_ACTIVE_ARTICLE
  };
}

export function getArticle(urlApi, articleId) {
  return {
    type: FETCH_ARTICLE.REQUEST,
    urlApi,
    articleId
  };
}
