import React from "react";
import { Trans, withNamespaces } from "react-i18next";
import Paragraph from "../../components/paragraph";
import Title from "../../components/title";

// Import model page
import SimpleContent from "../simple-content";

// Import components
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import ParagraphBreaks from "../../components/paragraph-breaks";
import LinkButton from "../../components/link-button";

const Cgu = props => {
  const { t } = props;
  return (
    <SimpleContent id="article" titlePage={t("cgu.title")}>
      <ScrollToTopOnMount />
      <ParagraphBreaks text={t("cgu.contents.intro")} />
      <Title titleType={2}>{t("cgu.contents.block1.title")}</Title>
      <ParagraphBreaks text={t("cgu.contents.block1.paragraphs")} />
      <Title titleType={2}>{t("cgu.contents.block2.title")}</Title>
      <ParagraphBreaks text={t("cgu.contents.block2.paragraphs")} />
      <Title titleType={2}>{t("cgu.contents.block3.title")}</Title>
      <ParagraphBreaks text={t("cgu.contents.block3.paragraphs")} />
      <Title titleType={2}>{t("cgu.contents.block4.title")}</Title>
      <Paragraph noMargin>{t("cgu.contents.block4.paragraph1")}</Paragraph>
      <Paragraph>
        <Trans i18nKey="cgu.contents.block4.paragraph2">
          See privacy policy :
          <LinkButton
            styletype="link"
            nostyle
            to={"/politique-confidentialite"}
          >
            policy link label
          </LinkButton>
        </Trans>
      </Paragraph>
      <Title titleType={2}>{t("cgu.contents.block5.title")}</Title>
      <ParagraphBreaks text={t("cgu.contents.block5.paragraphs")} />
    </SimpleContent>
  );
};

export default withNamespaces("translations")(Cgu);
