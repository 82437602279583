import createSagaMiddleware from "redux-saga";
import { all, takeLatest, fork } from "redux-saga/effects";

// NEW
// import sagaHomePage from "../containers/homepage/saga";
// import configurationSaga from "./configuration/saga";
import { watchGetArticles, watchGetArticle } from "./article/saga";
import { watchGetCategories } from "./category/saga";
import { watchCreateNewsletter } from "./newsletter/saga";
import { watchCreateVote } from "./vote/saga";
// import voteSaga from "./vote/saga";
// import newsletterSaga from "./newsletter/saga";

export const sagaMiddleware = createSagaMiddleware();

export default function* mainSaga() {
  // while (true){
  yield all([
    fork(watchGetArticles),
    fork(watchGetArticle),
    fork(watchGetCategories),
    fork(watchCreateNewsletter),
    fork(watchCreateVote)
  ]);
  // }
  /*  yield fork(watchGetArticles);
  yield fork(watchGetArticle);
  yield fork(watchGetCategories);*/
}
